import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  profileDetailsResponse: [],
  ContactDetails: [],
  WebsiteCourseDetails: [],
  WebsiteFacultyDetails: [],
  WebsiteAdmissionDetails: [],
  WebsiteDepartmentDetails: [],
  WebsitePlacementDetails: [],
  WebsiteCourseAdmissionDetails: [],
  WebsiteSingleAdmissionDetails: [],
  SaveWebsiteAdmissionDetails: [],
  WebsiteGalleryDetails: [],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: 'Location Details',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getProfileSuccess(state, action) {
      state.isLoading = false;
      state.profileDetailsResponse = action.payload;
    },
    postContactSucc(state, action) {
      state.isLoading = false;
      state.ContactDetails = action.payload;
    },
    getWebsiteCourseSucc(state, action) {
      state.isLoading = false;
      state.WebsiteCourseDetails = action.payload;
    },
    getWebsiteFacultySucc(state, action) {
      state.isLoading = false;
      state.WebsiteFacultyDetails = action.payload;
    },
    getWebsiteAdmissionSucc(state, action) {
      state.isLoading = false;
      state.WebsiteAdmissionDetails = action.payload;
    },
    getWebsiteDepartmentSucc(state, action) {
      state.isLoading = false;
      state.WebsiteDepartmentDetails = action.payload;
    },
    getWebsitePlacementSucc(state, action) {
      state.isLoading = false;
      state.WebsitePlacementDetails = action.payload;
    },
    getWebsiteGallerySucc(state, action) {
      state.isLoading = false;
      state.WebsiteGalleryDetails = action.payload;
    },
    getWebsiteCourseAdmissionSucc(state, action) {
      state.isLoading = false;
      state.WebsiteCourseAdmissionDetails = action.payload;
    },
    getWebsiteSingleAdmissionSucc(state, action) {
      state.isLoading = false;
      state.WebsiteSingleAdmissionDetails = action.payload;
    },
    postWebsiteAddSucc(state, action) {
      state.isLoading = false;
      state.SaveWebsiteAdmissionDetails = action.payload;
    },

  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

// export function getProfileDetails(ticketId,data) { 
//     return async () => {
//       dispatch(slice.actions.startLoading());
//       try {
//         const response = await axios.post(`/view_profile/${ticketId}`,data) ;
//         dispatch(slice.actions.getProfileSuccess(response));
//       } catch (error) {
//         dispatch(slice.actions.hasError(error));
//       }
//     };
//   }
export function getProfileDetails(data) {
  console.log(data, 'dfgdfg0dfg');
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/institute_profile_preview/`, data);
      dispatch(slice.actions.getProfileSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearProfileDetails(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getProfileSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function SaveContactData(data) {
  console.log(data, '220505005');
  return async () => {
    dispatch(slice.actions.startLoading());
    //   const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/institute_contact_us/', data);
      console.log(response.data, '2205050056');

      if (response && response !== '') {
        dispatch(slice.actions.postContactSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function ClearaddContact(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.postContactSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getWebsiteCourse(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_website_course/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getWebsiteCourseSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getWebsiteFaculty(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_website_faculty/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getWebsiteFacultySucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getWebsiteAdmission(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_website_admission/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getWebsiteAdmissionSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getWebsiteDepartment(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_website_department/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getWebsiteDepartmentSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}
export function getWebsitePlacement(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_website_placement/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getWebsitePlacementSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}


export function getWebsiteGallery(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_website_gallery/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getWebsiteGallerySucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}


export function getWebsiteCourseAdmission(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_course_admissions/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getWebsiteCourseAdmissionSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}
export function getWebsiteSingleAdmission(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_single_admission/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getWebsiteSingleAdmissionSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}


export function SaveWebsiteAdmission(data) {
  console.log(data, '220505005');
  return async () => {
    dispatch(slice.actions.startLoading());
    //   const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/save_institute_applicants/', data);
      console.log(response.data, '2205050056');

      if (response && response !== '') {
        dispatch(slice.actions.postWebsiteAddSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function Clearaddmissionapplicant(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.postWebsiteAddSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}