import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import { PATH_DASHBOARD } from '../routes/paths';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default function GuestGuard({ children }) {
  const { isAuthenticated } = useAuth();
  const ProfileComplete = localStorage.getItem('companyProfile');
  console.log(ProfileComplete, 'ProfileComplete43534')
  // if (isAuthenticated && ProfileComplete && ProfileComplete!=='' && ProfileComplete!==null && ProfileComplete === 'Filled') {
  //   return <Navigate to={PATH_DASHBOARD.app} />;
  // }
  if (isAuthenticated) {
    // return <Navigate to={PATH_DASHBOARD.general.profile} />;
    return <Navigate to={PATH_DASHBOARD.app} />;

  }

  return <>{children}</>;
}
