import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  industriesData: [],
  UpdateCategoryData:[],
  UpdateEmpCategoryData:[],
  industriesSecData:[],
  UpdateCategorySecData:[],
  ChangeCategorySecData:[],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getIndustriesSucc(state, action) {
      state.isLoading = false;
      state.industriesData = action.payload;
    },
    getIndustriesSecondarySucc(state, action) {
      state.isLoading = false;
      state.industriesSecData = action.payload;
    },
    CategoryUpdateSuccess(state, action) {
      state.isLoading = false;
      state.UpdateCategoryData = action.payload;
    },

    CategoryEmployeeUpdateSuccess(state, action) {
      state.isLoading = false;
      state.UpdateEmpCategoryData = action.payload;
    },

    CategoryUpdateSecSuccess(state, action) {
      state.isLoading = false;
      state.UpdateCategorySecData = action.payload;
    },
    CategoryParamsSucc(state, action) {
      state.isLoading = false;
      state.ChangeCategorySecData = action.payload;
    },
    ClearCategoryParamsSucc(state, action) {
      state.isLoading = false;
      state.ChangeCategorySecData = action.payload;
    },

    // CREATE EVENT
 
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function getIndustriesData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.get('/get_MEA_categories/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization:
            `Bearer ${tokenVal}`,
        },
      });
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.getIndustriesSucc(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}
export function getIndustriesSecData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.get('/get_industries/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization:
            `Bearer ${tokenVal}`,
        },
      });
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.getIndustriesSecondarySucc(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
      } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}
export function updateCategory(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const dataArray = {
      trainer_industries_array:data
    }
    try {
      const response = await axios.post('/save_MEA_categories/',dataArray)
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.CategoryEmployeeUpdateSuccess(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
     } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}
export function updateSubCategory(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const dataArray = {
      talent_industries_array:data
    }
    try {
      const response = await axios.post('/save_talent_industries/',dataArray)
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.CategoryUpdateSecSuccess(response.data));
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
        if((error && error.status !== "") && (error.status !== undefined) ){
          dispatch(slice.actions.hasError(error));
        } else{
          dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
        } 
    }
  };
}

export function sendParamsToCategory(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.CategoryParamsSucc(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearParamsToCategory(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.ClearCategoryParamsSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function ClearUpdateCategory(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.CategoryUpdateSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function clearUpdateSubCategory(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.CategoryUpdateSecSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearLanguageIndustry(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.CategoryUpdateSuccess([]));
      
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearCategoryEmployeeUpdateSuccess(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.CategoryEmployeeUpdateSuccess([]));
      
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearHasError(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.hasError([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}