import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Button } from '@mui/material';
// import Social Media
import Iconify from '../../components/Iconify';
// ----------------------------------------------------------------------
export default function SocialMedia() {
    const SocialMedia = (
    <div className="socialMedia">          
        <Button className='google' variant="contained" href="#contained-buttons">
            <Iconify
            icon={'bxl:google'}
            sx={{  width: 24, height: 24 }}
        />Google
        </Button>
        <Button className='facebook' variant="contained" href="#contained-buttons">
            <Iconify
            icon={'bxl:facebook'}
            sx={{  width: 24, height: 24 }}
        />Facebook
        </Button>
        <Button className='instagram' variant="contained" href="#contained-buttons">
            <Iconify
            icon={'bxl:instagram'}
            sx={{  width: 24, height: 24 }}
        />Instagram
        </Button>
        <Button className='linkedin' variant="contained" href="#contained-buttons">
            <Iconify
            icon={'bxl:linkedin'}
            sx={{ width: 24, height: 24 }}
        />Linkedin
        </Button>
        <Button className='behance' variant="contained" href="#contained-buttons">
            <Iconify
            icon={'bxl:behance'}
            sx={{ width: 24, height: 24 }}
        />Behance
        </Button>
        <Button className='twitter' variant="contained" href="#contained-buttons">
            <Iconify
            icon={'bxl:twitter'}
            sx={{ width: 24, height: 24 }}
        />Twitter
        </Button>
    </div>
    );
  return <RouterLink to="/">{SocialMedia}</RouterLink>;
}
