

import React, { useCallback, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Dialog,
  Box,
  Grid
} from '@mui/material';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from '../../../redux/store';
import { updateProfileProfile, getBannersWebsite, UpdateBannersWebsite, } from '../../../redux/slices/companyProfile';
import { RHFUploadSingleFile, FormProvider } from '../../../components/hook-form';

const EditProfileImageModel = (props) => {
  const { onClose, selectedValue, openn, isEdit, currentProduct, currentUser, classIndex } = props;
  const handleClose = () => {
    onClose(); reset();
  };
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleOpenPreview = () => {
    setOpen(true);
  };

  const handleClosePreview = () => {
    setOpen(false);
    onClose()
  };

  const NewBlogSchema = Yup.object().shape({
    cover: Yup.mixed().required('Cover is required'),
  });

  const { statesValue } = useSelector((state) => {
    return {
      statesValuess: state

    };
  });
  const awardsData = currentUser;

  const defaultValues = useMemo(
    () => ({
      cover: currentUser || null,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [awardsData]
  );

  useEffect(() => {
    if (isEdit && currentUser) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }

  }, [isEdit, currentUser])

  const methods = useForm({
    resolver: yupResolver(NewBlogSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    setError,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = methods;

  const values = watch();
  const formData = new FormData();

  const onSubmit = async (data) => {
    formData.append('files', data.cover);
    formData.append('position', classIndex);

    if (typeof data.cover === 'string' || data.cover instanceof String) {
      const urlCheck = data && data.cover ? data.cover.includes("https") : ""
      enqueueSnackbar('Please Select File For Upload', {
        variant: 'error',
      });
    } else {
      try {
        await new Promise((resolve) => setTimeout(resolve, 500));
        dispatch(UpdateBannersWebsite(formData))
        // reset();
        handleClose()
      } catch (error) {
        console.error(error);
      }
    }
  };

  // const handleDrop = useCallback(
  //   (acceptedFiles) => {
  //     const file = acceptedFiles[0];

  //     if (file) {
  //       setValue(
  //         'cover',
  //         Object.assign(file, {
  //           preview: URL.createObjectURL(file),
  //         })
  //       );
  //     }
  //   },
  //   [setValue]
  // );

  const acceptedWidth = 1920; // Desired width
  // const acceptedHeight = 1280; // Desired height
  const acceptedHeight = 600; // Desired height

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file !== undefined) {
        const reader = new FileReader();
        const img = new Image();
        img.src = window.URL.createObjectURL(file);
        img.onload = () => {
          // if (img.width < img.height) {
          // if (img.width === acceptedWidth && img.height === acceptedHeight) {
          if (img.width >= acceptedWidth && img.height >= acceptedHeight) {

            setValue(
              'cover',
              Object.assign(file, {
                preview: URL.createObjectURL(file),
                position: 1
              })
            );

          } else {
            setError('cover', 'Please use images with dimensions above 1920 x 600 pixels.');
            enqueueSnackbar('Please use images with dimensions above 1920 x 600 pixels.', {
              variant: 'error',
            });
          }
        };
      }
    },
    [setValue]
  );


  return (

    <Dialog maxWidth="xs" onClose={handleClose} sx={{}} open={openn} fullWidth>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            margin: 4,
            width: 293,
          }}
        >
          <Box xs={12} md={4} sx={{ marginRight: 2, marginTop: 1, display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={{ mb: 0.75, color: '#44577a', fontSize: 16, fontWeight: 600 }}>Company Profile</Typography>
          </Box>
          <Box sx={{ marginTop: 2 }}>

            <Box sx={{ marginTop: 2 }} >
              <Typography sx={{ mb: 0.75, color: '#44577a', fontSize: 14 }}>Update Profile Image</Typography>
              <Box className="uploadAwards hidePlaceholder" sx={{}} >
                <RHFUploadSingleFile name="cover" accept="image/*" maxSize={3145728} onDrop={handleDrop} />
              </Box>
            </Box>
          </Box>
          <Box sx={{ marginTop: 4 }}>
            <Grid container xs={12} spacing={2}>
              <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <LoadingButton
                  variant="contained"
                  onClick={handleClose}
                  sx={{
                    backgroundColor: '#D3E1EA',
                    width: '-webkit-fill-available',
                    '&:hover': {
                      backgroundColor: '#d3e1dd',
                    },
                  }}
                >
                  <Typography sx={{ color: '#607391', fontSize: 14 }}>Cancel</Typography>
                </LoadingButton>
              </Grid>
              <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  className="sumbitBtn"
                  sx={{ width: '-webkit-fill-available' }}
                >
                  <Typography sx={{ color: '#FFFFFF', fontSize: 14 }}>Upload</Typography>
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </FormProvider>
    </Dialog>

  )
}
EditProfileImageModel.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default EditProfileImageModel  