import { useContext } from 'react';
import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import { useDropzone } from 'react-dropzone';

// @mui
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
//
import Image from '../Image';
import Iconify from '../Iconify';
import RejectionFiles from './RejectionFiles';


// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  width: 144,
  height: 200,
  margin: 'auto',
}));

const DropZoneStyle = styled('div')({
  width: '100%',
  height: '100%',
  alignItems: 'center',
});



// ----------------------------------------------------------------------

UploadWithoutDefaultImage.propTypes = {
  error: PropTypes.bool,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  helperText: PropTypes.node,
  sx: PropTypes.object,
};

export default function UploadWithoutDefaultImage({ error, file, helperText, sx, ...other }) {

  const PlaceholderStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    height: "100%",
  }));
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    ...other,
  });

  return (
    <>
      <RootStyle
        sx={{
          ...((isDragReject || error) && {
            borderColor: 'error.light',
          }),
          ...sx,
        }}
      >
        <DropZoneStyle
          {...getRootProps()}
          sx={{
            ...(isDragActive && { opacity: 0.72 }),
          }}
        >
          <input {...getInputProps()} />

          {file && <Image alt="avatar" src={isString(file) ? file : file.preview}  />}

          <PlaceholderStyle
            className=""
          >
         
            <Typography variant="caption" style={{ fontSize:'15px', color: '#44577a' ,cursor:'pointer'}}>{file ? '' : '+ Add Image'}</Typography>
          </PlaceholderStyle>
        </DropZoneStyle>
      </RootStyle>

      {helperText && helperText}

      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}
    </>
  );
}
