import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    couponError: null,
    subscriptionData: [],
    paymentHistory: [],
    subscriptionActivateData: [],
    cartData: [],
    deletedCartData: [],
    billingInfoData: [],
    savedBillingGSTData: [],
    couponCodeData: [],
    savedBillingInfoData: [],
    cartCount: [],
    paymentInfo: [],
    activeSubscriptionData: [],
    couponDiscount: [],
    removeCouponCodeData: [],

};

const slice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        hasCouponError(state, action) {
            state.isLoading = false;
            state.couponError = action.payload;
        },
        // GET EVENTS
        getActivateSubscriptionSucc(state, action) {
            state.isLoading = false;
            state.subscriptionActivateData = action.payload;
        },
        getSubscriptionSucc(state, action) {
            state.isLoading = false;
            state.subscriptionData = action.payload;
        },
        paymentHistorySucc(state, action) {
            state.isLoading = false;
            state.paymentHistory = action.payload;
        },
        getCartSucc(state, action) {
            state.isLoading = false;
            state.cartData = action.payload;
        },
        getDeleteCartDataSucc(state, action) {
            state.isLoading = false;
            state.deletedCartData = action.payload;
        },
        getBillingInfoSucc(state, action) {
            state.isLoading = false;
            state.billingInfoData = action.payload;
        },
        getCouponCodeDataSucc(state, action) {
            state.isLoading = false;
            state.couponCodeData = action.payload;
        },
        saveBillingInfoSucc(state, action) {
            state.isLoading = false;
            state.savedBillingInfoData = action.payload;
        },
        saveBillingGSTSucc(state, action) {
            state.isLoading = false;
            state.savedBillingGSTData = action.payload;
        },
        getCartCountSucc(state, action) {
            state.isLoading = false;
            state.cartCount = action.payload;
        },
        savePaymentInfoSucc(state, action) {
            state.isLoading = false;
            state.paymentInfo = action.payload;
        },
        getActiveSubscriptionSucc(state, action) {
            state.isLoading = false;
            state.activeSubscriptionData = action.payload;
        },
        getCouponDiscountSucc(state, action) {
            state.isLoading = false;
            state.couponDiscount = action.payload;
        },
        getRemoveCouponCodeDataSucc(state, action) {
            state.isLoading = false;
            state.removeCouponCodeData = action.payload;
        },
        getPaymentInvoiceDataSucc(state, action) {
            state.isLoading = false;
            state.PaymentInvoiceDataResp = action.payload;
        },


        // CREATE EVENT
    },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function getTrainerSubscription(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/get_trainer_subscription/', data);
            if (response && response.data && response.data.status === 200) {
                dispatch(slice.actions.getSubscriptionSucc(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data));
            }
        } catch (error) {
            if (error && error.status !== '' && error.status !== undefined) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
            }
        }
    };
}

export function getPaymentHistory(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/get_trainer_subscription_history/', data)
            if (response && response.data && response.data.status === 200) {
                dispatch(slice.actions.paymentHistorySucc(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data))
            }
        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}


export function getCartCount(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/get_cart_count/', data);
            if (response && response.data && response.data.status === 200) {
                dispatch(slice.actions.getCartCountSucc(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data));
            }
        } catch (error) {
            if (error && error.status !== '' && error.status !== undefined) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
            }
        }
    };
}

export function clearTrainerSubscription() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.getSubscriptionSucc([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function clearGetCartCount() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.getCartCountSucc([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getTrainerActivateSubscription(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/activate_subscription_plan/', data);
            if (response && response.data && response.data.status === 200) {
                dispatch(slice.actions.getActivateSubscriptionSucc(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data));
            }
        } catch (error) {
            if (error && error.status !== '' && error.status !== undefined) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
            }
        }
    };
}

export function clearActivation() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.getActivateSubscriptionSucc([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}



export function clearHasError(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.hasError([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function getCartData(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/trainer_add_to_cart/', data)
            if (response && response.data && response.data.status === 200) {
                dispatch(slice.actions.getCartSucc(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data))
            }
        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}


export function clearCartData() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.getCartSucc([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function deleteCartData(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/remove_cart_item/', data)
            if (response && response.data && response.data.status === 200) {
                dispatch(slice.actions.getDeleteCartDataSucc(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data))
            }
        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}


export function getRemoveCouponCodeData(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/remove_coupon_code/', data)
            if (response && response.data && response.data.status === 200) {
                dispatch(slice.actions.getRemoveCouponCodeDataSucc(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data))
            }
        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}


export function clearDeleteCartData() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.getDeleteCartDataSucc([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// coupon code
export function getCouponCodeData(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/apply_coupon_code/', data)
            if (response && response.data && response.data.status === 200) {
                dispatch(slice.actions.getCouponCodeDataSucc(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data))
            }
        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(slice.actions.hasCouponError(error));
            } else {
                dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}

export function clearGetCouponCodeData() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.getCouponCodeDataSucc([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function clearHasCouponError(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.hasCouponError([]));
        } catch (error) {
            dispatch(slice.actions.hasCouponError(error));
        }
    };
}

// biling info

export function getBillingInfo(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/get_billing_info/', data);
            if (response && response.data && response.data.status === 200) {
                dispatch(slice.actions.getBillingInfoSucc(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data));
            }
        } catch (error) {
            if (error && error.status !== '' && error.status !== undefined) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
            }
        }
    };
}

// export function getBillingInfo(data) {
//     return async () => {
//         dispatch(slice.actions.startLoading());
//         try {
//             const response = await axios.post('/get_billing_info/', data)
//             if (response && response.data && response.data.status === 200) {
//                 dispatch(slice.actions.getBillingInfoSucc(response.data));
//             } else {
//                 dispatch(slice.actions.hasError(response.data))
//             }
//         } catch (error) {
//             if ((error && error.status !== "") && (error.status !== undefined)) {
//                 dispatch(slice.actions.hasError(error));
//             } else {
//                 dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
//             }
//         }
//     };
// }

export function clearBillingInfo() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.getBillingInfoSucc([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function saveBillingInfo(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/save_billing_info/', data)
            if (response && response.data && response.data.status === 200) {
                dispatch(slice.actions.saveBillingInfoSucc(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data))
            }
        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}


export function saveBillingGST(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/save_trainer_gst_details/', data)
            if (response && response.data && response.data.status === 200) {
                dispatch(slice.actions.saveBillingGSTSucc(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data))
            }
        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}

export function clearSaveBillingInfoData() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.saveBillingInfoSucc([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function clearSaveBillingGSTData() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.saveBillingGSTSucc([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function savePaymentInfo(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/save_payment_info/', data)
            if (response && response.data && response.data.status === 200) {
                dispatch(slice.actions.savePaymentInfoSucc(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data))
            }
        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}

export function clearSavePaymentInfo() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.savePaymentInfoSucc([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getActiveSubscription(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/get_trainer_activate_subscription/', data);
            if (response && response.data && response.data.status === 200) {
                dispatch(slice.actions.getActiveSubscriptionSucc(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data));
            }
        } catch (error) {
            if (error && error.status !== '' && error.status !== undefined) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
            }
        }
    };
}
// export function getActiveSubscription(data) {
//     return async () => {
//         dispatch(slice.actions.startLoading());
//         try {
//             const response = await axios.get('/get_trainer_activate_subscription/', data);
//             if (response && response.data && response.data.status === 200) {
//                 dispatch(slice.actions.getActiveSubscriptionSucc(response.data));
//             } else {
//                 dispatch(slice.actions.hasError(response.data));
//             }
//         } catch (error) {
//             if (error && error.status !== '' && error.status !== undefined) {
//                 dispatch(slice.actions.hasError(error));
//             } else {
//                 dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
//             }
//         }
//     };
// }

export function getCouponDiscount(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/get_coupons/', data);
            if (response && response.data && response.data.status === 200) {
                dispatch(slice.actions.getCouponDiscountSucc(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data));
            }
        } catch (error) {
            if (error && error.status !== '' && error.status !== undefined) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
            }
        }
    };
}

export function clearCouponDiscount() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.getCouponDiscountSucc([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getPaymentInvoiceData(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/get_subscription_invoice_details/', data);
            if (response && response.data && response.data.status === 200) {
                dispatch(slice.actions.getPaymentInvoiceDataSucc(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data));
            }
        } catch (error) {
            if (error && error.status !== '' && error.status !== undefined) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
            }
        }
    };
}

export function ClearPaymentInvoiceData() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.getPaymentInvoiceDataSucc([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
