import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  events: [],
  getKeywords:[],
  getProfessiondata:[],
  quickSearchSuccess:[],
  searchSuccess:[],
  getAllSearch:[],
  deleteSuccess:[],
  getSearchTalentSucc:[],
  getSavedSearchTalentSucc:[],
  searchSuccessGetSuccess:[],
  likeSearchSucResp:[],
  talentRecomendationResp:[],
  SavedTalentSearchSuccResp:[],
  searchlogdatasuccResp:[],
  
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS

    getKeywordsSuccess(state, action) {
      state.isLoading = false;
      state.getKeywords = action.payload;
    },

   getProfessionsSuccess(state, action) {
      state.isLoading = false;
      state.getProfessiondata = action.payload;
    },

    getAllSearchSuccess(state, action) {
      state.isLoading = false;
      state.getAllSearch = action.payload;
    },

    getSearchTalentSuccess(state, action) {
      state.isLoading = false;
      state.getSearchTalentSucc = action.payload;
    },

    getSavedSearchTalentSuccess(state, action) {
      state.isLoading = false;
      state.getSavedSearchTalentSucc = action.payload;
    },

    quickSearchSuccess(state, action) {
      state.isLoading = false;
      state.quickSearchSuccess = action.payload;
    },

    likeSearchSuccess(state, action) {
      state.isLoading = false;
      state.likeSearchSucResp = action.payload;
    },

    talentRecomendationSuccess(state, action) {
      state.isLoading = false;
      state.talentRecomendationResp = action.payload;
    },

    SearchLogDataSuccess(state, action) {
      state.isLoading = false;
      state.searchlogdatasuccResp = action.payload;
    },

    saveSearchSuccess(state, action) {
      state.isLoading = false;
      state.searchSuccess = action.payload;
    },

    deleteSearchSuccess(state, action) {
      state.isLoading = false;
      state.deleteSuccess = action.payload;
    },

    SavedTalentSearchSuccess(state, action) {
      state.isLoading = false;
      state.SavedTalentSearchSuccResp = action.payload;
    },

    searchSuccessGet(state, action) {
      state.isLoading = false;
      state.searchSuccessGetSuccess = action.payload;
    },

  },
});   

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function getKeywords() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get_all_keywords/');
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.getKeywordsSuccess(response.data)); 
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function getProfessions(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/dev_get_professions/',data);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.getProfessionsSuccess(response.data)); 
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function getAllSearch() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get_all_search/');
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.getAllSearchSuccess(response.data)); 
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}


export function getSavedTalent(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_search_talent/',data);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.getSearchTalentSuccess(response.data)); 
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function getSavedSearchTalent(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/save_search_talent/',data);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.getSavedSearchTalentSuccess(response.data)); 
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function saveQuickSearch(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/new_quick_search/',data);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.quickSearchSuccess(response.data));  
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
   } catch (error) {
    if((error && error.status !== "") && (error.status !== undefined) ){
      dispatch(slice.actions.hasError(error));
    } else{
      dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
    }
    }
  };
}

// export function saveQuickSearch(data) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.post('/quick_search/',data);
//       if(response && response.data && response.data.status === 200 ){
//         dispatch(slice.actions.quickSearchSuccess(response.data));  
//       }else{
//         dispatch(slice.actions.hasError(response.data))
//       }
//    } catch (error) {
//     if((error && error.status !== "") && (error.status !== undefined) ){
//       dispatch(slice.actions.hasError(error));
//     } else{
//       dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
//     }
//     }
//   };
// }

export function saveSearch(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/save_search/',data);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.saveSearchSuccess(response.data)); 
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function deleteSearch(data) {
  return async () => {
    dispatch(slice.actions.startLoading());  
    try {
      const response = await axios.post('/delete_search/',data);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.deleteSearchSuccess(response.data)); 
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function SavedTalentSearch(data) {
  return async () => {
    dispatch(slice.actions.startLoading());  
    try {
      const response = await axios.post('/search_save_talent/',data);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.SavedTalentSearchSuccess(response.data)); 
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function searchSaveQuick(data){ 
  return async () => {
    dispatch(slice.actions.startLoading());   
    try { 
     const response = await axios.post('/get_quick_search/',data);
     if(response && response.data && response.data.status === 200 ){
      dispatch(slice.actions.searchSuccessGet(response.data));  
    }else{
      dispatch(slice.actions.hasError(response.data))
    }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function saveAdvanceSearch(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/advance_search/',data);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.quickSearchSuccess(response.data));  
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function LikeSearch(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/save_search_talent/',data);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.likeSearchSuccess(response.data));  
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function getTalentRecommendations(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/dev_get_recommendations/',data);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.talentRecomendationSuccess(response.data));  
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function getSearchLogData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_employer_search_log/',data);
      if(response && response.data && response.data.status === 200 ){
        dispatch(slice.actions.SearchLogDataSuccess(response.data));  
      }else{
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if((error && error.status !== "") && (error.status !== undefined) ){
        dispatch(slice.actions.hasError(error));
      } else{
        dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
      }
    }
  };
}

export function ClearSearchLogSearch(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.SearchLogDataSuccess([])); 
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
};
}

export function ClearLikeSearch(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.likeSearchSuccess([])); 
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
};
}

export function ClearSearch(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.deleteSearchSuccess([]));
      dispatch(slice.actions.saveSearchSuccess([])); 
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
};
}

export function ClearSavedTalentSearch(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.SavedTalentSearchSuccess([])); 
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
};
}

export function ClearSearchData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.searchSuccessGet([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
};
}

export function ClearSavedTalentSearchData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getSearchTalentSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
};
}

export function ClearsaveQuickSearch(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.quickSearchSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
};
}

export function ClearQuickSearch(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.quickSearchSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
};
}

export function clearHasError(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.hasError([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

























