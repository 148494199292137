import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  getJobsGetData: [],
  notificationData: [],
  SingleJobNotification: [],
  enquiryData: [],
  deleteEnquiryData: [],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
  EnquiryLikeData: [],
  notificationJobData: [],
  deleteJobMsgResp: [],
  SendReplyData: [],
  inboxNotiData: [],
  inboxData: [],
  deleteNotificationResData: [],
  notificationListData: [],
  notificationReplyJob: [],
  deleteNotificationListData: [],
  getTrainerInboxData: [],
  getTrainerEmployerInboxData: [],
  notificationLikeData: [],
  likeTrainerNotificationResp: [],
  deleteTrainerNotificationResp: [],
  sendMessageToEmployerData: [],
  seenTrainerNotificationResp: [],
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS  
    getNotificationSucc(state, action) {
      state.isLoading = false;
      state.notificationData = action.payload;
    },
    getReplyJobNotification(state, action) {
      state.isLoading = false;
      state.notificationReplyJob = action.payload;
    },
    getSingleJobNotification(state, action) {
      state.isLoading = false;
      state.SingleJobNotification = action.payload;
    },
    getInboxSuccess(state, action) {
      state.isLoading = false;
      state.inboxData = action.payload;
    },
    getEnquirySucc(state, action) {
      state.isLoading = false;
      state.enquiryData = action.payload;
    },
    DeleteEnquirySucc(state, action) {
      state.isLoading = false;
      state.deleteEnquiryData = action.payload;
    },
    EnquiryLikeSucc(state, action) {
      state.isLoading = false;
      state.EnquiryLikeData = action.payload;
    },
    SendReplyEnquiry(state, action) {
      state.isLoading = false;
      state.SendReplyData = action.payload;
    },
    getdeleteJobMsg(state, action) {
      state.isLoading = false;
      state.deleteJobMsgResp = action.payload;
    },
    getNotiInboxSuccess(state, action) {
      state.isLoading = false;
      state.inboxNotiData = action.payload;
    },
    getJobNotificationSucc(state, action) {
      state.isLoading = false;
      state.notificationJobData = action.payload;
    },
    getNotificationListRes(state, action) {
      state.isLoading = false;
      state.notificationListData = action.payload;
    },
    DeleteNotification(state, action) {
      state.isLoading = false;
      state.deleteNotificationResData = action.payload;
    },
    deleteNotificationListRes(state, action) {
      state.isLoading = false;
      state.deleteNotificationListData = action.payload;
    },
    getJobsValues(state, action) {
      state.isLoading = false;
      state.getJobsGetData = action.payload;
    },
    getTrainerInboxSucc(state, action) {
      state.isLoading = false;
      state.getTrainerInboxData = action.payload;
    },

    getTrainerEmployerInboxSucc(state, action) {
      state.isLoading = false;
      state.getTrainerEmployerInboxData = action.payload;
    },

    LikeTrainerNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.likeTrainerNotificationResp = action.payload;
    },

    DeleteTrainerNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.deleteTrainerNotificationResp = action.payload;
    },

    notificationLikeSucc(state, action) {
      state.isLoading = false;
      state.notificationLikeData = action.payload;
    },

    sendMessageToEmployerSucc(state, action) {
      state.isLoading = false;
      state.sendMessageToEmployerData = action.payload;
    },

    SeenTrainerNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.seenTrainerNotificationResp = action.payload;
    },

    // postSkillSetSucc(state, action) {
    //     state.isLoading = false;
    //     state.PostSkillSetData = action.payload;
    //   },

    // CREATE EVENT
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function getJobsData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_job_notification/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getJobsValues(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      if (error && error.status !== '' && error.status !== undefined) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
      }
    }
  };
}


export function getJobNotificationData() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get_job_notification/');
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getJobNotificationSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      if (error && error.status !== '' && error.status !== undefined) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
      }
    }
  };
}

export function DeleteJobMsg(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/delete_job_notification/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getdeleteJobMsg(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function clearReplySuccess() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getReplyJobNotification(''));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function clearDeleteNotificationData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.DeleteNotification([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function DeleteNotificationData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/delete_inbox_notification/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.DeleteNotification(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      if (error && error.status !== '' && error.status !== undefined) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
      }
    }
  };
}

export function clearDeleteJobMsg() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getdeleteJobMsg(''));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSingleJobNotification(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_single_job_notification/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getSingleJobNotification(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getNotificationData() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get_job_notification/');
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getNotificationSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function DoEnquiryLike(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/like_trainer_enquiry/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.EnquiryLikeSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function ClearDoEnquiryLike(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.EnquiryLikeSucc([]));

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// export function getEnquiryData(data) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.get('/get_enquiry/');
//       if(response && response.data && response.data.status === 200 ){
//         dispatch(slice.actions.getEnquirySucc(response.data));
//         } else{
//         dispatch(slice.actions.hasError(response.data)) 
//       }
//     } catch (error) {
//       if((error && error.status !== "") && (error.status !== undefined) ){
//         dispatch(slice.actions.hasError(error));
//      } else{
//       dispatch(slice.actions.hasError({status:"502",message:"Something went wrong"}))
//     }
//     }
//   };
// }


export function getNotificationInboxData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get_notification/');
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getInboxSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      if (error && error.status !== '' && error.status !== undefined) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
      }
    }
  };
}

export function getEnquiryData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_trainer_enquiry/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getEnquirySucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}


export function DeleteEnquiryData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/delete_trainer_enquiry/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.DeleteEnquirySucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function clearDeleteEnquiryData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.DeleteEnquirySucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function SendReplyToPost(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/reply_enquiry/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.SendReplyEnquiry(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function ClearSendReplyToPost(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.SendReplyEnquiry([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getNotificationList(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get_notification/');
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getNotificationListRes(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getInboxNotificationData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_trainer_notification/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getNotiInboxSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      if (error && error.status !== '' && error.status !== undefined) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
      }
    }
  };
}

export function ClearNotiInbox(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getNotiInboxSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function deleteNotificationList(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/delete_notification/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.deleteNotificationListRes(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function replyJobNotification(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/reply_job_notification/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getReplyJobNotification(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}


export function clearDeleteNotificationList(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.deleteNotificationListRes([]));

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearHasError(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.hasError([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getTrainerInbox(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_trainer_inbox/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getTrainerInboxSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      if (error && error.status !== '' && error.status !== undefined) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
      }
    }
  };
}

export function doNotificationLike(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/like_inbox_notification/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.notificationLikeSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}


export function ClearDoNotificationLike(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.notificationLikeSucc([]));

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTrainerEmployerInbox(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_trainer_employer_inbox/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getTrainerEmployerInboxSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      if (error && error.status !== '' && error.status !== undefined) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
      }
    }
  };
}

export function ClearTrainerEmployerInbox(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getTrainerEmployerInboxSucc([]));

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function LikeTrainerNotifications(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/like_trainer_notification/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.LikeTrainerNotificationsSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      if (error && error.status !== '' && error.status !== undefined) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
      }
    }
  };
}

export function ClearLikeTrainerNotification(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.LikeTrainerNotificationsSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function DeleteTrainerNotifications(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/delete_trainer_notification/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.DeleteTrainerNotificationsSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      if (error && error.status !== '' && error.status !== undefined) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
      }
    }
  };
}

export function ClearDeleteTrainerNotification(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.DeleteTrainerNotificationsSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function SendMessageToEmployer(data) {
  console.log(data, 'tuok')
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/send_message_trainer/', data);
      console.log(response, 'rrrrrrrrr');
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.sendMessageToEmployerSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }

    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

// Clear sendMessageSucc
export function clearSendMessageToEmployer(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.sendMessageToEmployerSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function SeenTrainerNotifications(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/update_seen_status/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.SeenTrainerNotificationsSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      if (error && error.status !== '' && error.status !== undefined) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
      }
    }
  };
}


export function ClearSeenTrainerNotification(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.SeenTrainerNotificationsSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


