import PropTypes from 'prop-types';
import { noCase } from 'change-case';
import { useState, useEffect } from 'react';
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
// import DateFormat from 'src/components/DateFormat';
import DateFormat from '../../../components/DateFormat';
// utils
import { fToNow } from '../../../utils/formatTime';
import { PATH_DASHBOARD, PATH_AUTH } from '../../../routes/paths';
// _mock_
import { _notifications } from '../../../_mock';
// components
import Iconify from '../../../components/Iconify';

import Scrollbar from '../../../components/Scrollbar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import { getRecentNotification, ViewAllRecentNotification, ClearViewAllRecentNoti } from '../../../redux/slices/DashboardNotification';
import { useDispatch, useSelector } from '../../../redux/store';


// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const [notifications, setNotifications] = useState(_notifications);
  const [dashboardNotification, setDashboardNotification] = useState([{}]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { notificationData, ViewnotificationData } = useSelector((state => {
    return {

      notificationData: state && state.DashboardNotification && state.DashboardNotification.DashboardNotificationSucc && state.DashboardNotification.DashboardNotificationSucc && state.DashboardNotification.DashboardNotificationSucc.response,
      ViewnotificationData: state && state.DashboardNotification && state.DashboardNotification.ViewAllRecentNotiRespSucc,
    }
  }))

  console.log(ViewnotificationData, 'ViewnotificationData548')

  useEffect(() => {
    if (ViewnotificationData && ViewnotificationData.status === 200) {
      dispatch(ClearViewAllRecentNoti());
      navigate(PATH_DASHBOARD.inBox.talent, { replace: true });
    }
  }, [ViewnotificationData])


  // const totalUnRead = notifications.filter((item) => item.isUnRead === true).length;

  const totalUnRead = dashboardNotification.length;

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMarkAllAsRead = () => {
    setDashboardNotification(
      notificationData.map((notificationData) => ({
        ...notificationData,
        isUnRead: false,
      }))
    );
  };


  const handleIconClick = () => {
    dispatch(getRecentNotification());
  };

  useEffect(() => {
    if (notificationData && notificationData.notifications && notificationData.notifications.length > 0) {
      setDashboardNotification(notificationData.notifications);
    }
  }, [notificationData]);

  const handleButtonClick = () => {

    // navigate(PATH_DASHBOARD.inBox.talent, { replace: true });
    setOpen(null);
    dispatch(ViewAllRecentNotification());
  };


  return (
    <>
      <IconButtonAnimate color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 40, height: 40 }}>
        <Badge badgeContent={totalUnRead} color="error" onClick={handleIconClick}>
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnRead} read messages
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButtonAnimate color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" width={20} height={20} />
              </IconButtonAnimate>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: { xs: 340, sm: 350, md: 350 } }}>
          <List>
            {dashboardNotification.length === 0 ? (
              // Render a card or message when no data is found
              <Box sx={{ p: 2, textAlign: 'center' }}>
                <Typography variant="body2" color="text.secondary">
                  No notifications found.
                </Typography>
              </Box>
            ) : (
              // Render the list of notifications
              dashboardNotification.map((notification, index) => (
                <NotificationItem key={index} dashboardNotification={notification} />
              ))
            )}


          </List>
        </Scrollbar>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple onClick={handleButtonClick}>
            View All
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------


function NotificationItem({ dashboardNotification }) {
  // const { avatar, title } = renderContent(notification);

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        borderBottom: "1px solid eee",
        mt: '1px',
        ...(dashboardNotification.isUnRead && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{dashboardNotification.icon_image}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={dashboardNotification.message}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {/* {fToNow(notification.createdAt)} */}
            <DateFormat date={dashboardNotification.created_at} />
          </Typography>
        }
      />
    </ListItemButton>
  );
}

