import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { List, Box, ListSubheader, Button, Card, CardContent, Grid, Typography, Avatar } from '@mui/material';
//
import { NavListRoot } from './NavList';
import useResponsive from '../../../hooks/useResponsive';

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shorter,
    }),
  })
);

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  isCollapse: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function NavSectionVertical({ navConfig, IsProfile, isCollapse = false, updateImg, ...other }) {
  const str1 = localStorage.getItem("userMenu");
  const [str, setStr] = useState(str1);
  useEffect(() => {
    if (str1 && str1 !== '') {
      const str2 = localStorage.getItem("userMenu");
      setStr(str2);
    }
  }, []);
  const MenuObj = JSON.parse(str);
  const [ListOfMenus, setListOfMenus] = useState([]);

  const mylist = [];
  useEffect(() => {
    if (navConfig && navConfig.length > 0) {
      const sortedObject = navConfig.map((talent) => {
        // items
        const sortedData = talent.items && talent.items.length > 0 ? talent.items.map((item) => {
          // title
          const myData = MenuObj && MenuObj.length > 0 ? MenuObj.map((key) => {
            // title
            if (key.menu_name === item.title) {
              console.log(key, item, 'myData43', IsProfile)
              if (key.menu_name !== 'Profile') {
                mylist.push(item);
              }
              // if(IsProfile === 'Yes' && key.menu_name !== 'Profile'){
              //   mylist.push(item);
              // }
              // mylist.push(item);
            }
            return key;
          }
          ) : [];
          return item;
        }
        ) : [];
        return talent;
      }
      );
      setListOfMenus(mylist);
    }
  }, [navConfig]);

  const insName = localStorage.getItem('CompanyName');

  console.log(ListOfMenus, 'ListOfMenus', MenuObj);


  const isDesktop = useResponsive('up', 'lg');


  console.log(insName, 'avatarfdgf5fg',);
  return (
    <Box {...other} >
      {!isDesktop &&
        <Box mt={3} p={1}>
          {/* {isLoading ?
          <Skeleton animation="wave" variant="rounded" sx={{ backgroundColor: "#e4e4e4", height: '6rem', width: '100%' }} />
          : */}
          <Card className='cardRadius'>
            <CardContent style={{ padding: "12px" }}>
              <Grid container>
                <Grid item xs={4}>
                  <Box>
                    <Avatar sx={{ height: "55px", width: "55px", textAlign: 'center', borderRadius: "50px" }} src={updateImg} />
                  </Box>
                </Grid>
                <Grid item xs={8}>
                  <Typography gutterBottom sx={{ fontSize: "13px", color: "#777777" }}>Institution Dashboard  </Typography>

                  <Typography className='transform' sx={{ fontSize: "16px", color: "black", fontWeight: "600" }}>{insName && insName !== '' ?
                    <span>{insName.length > 18 ? insName.substring(0, 18).concat("...") : insName} </span> : 'Institution Name'} </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          {/* } */}
        </Box>
      }

      {/* <Box component={Button} mt={3} className="sidebar-user-info" onClick={() => navigate(PATH_DASHBOARD.setting)} fullWidth> */}

      <List className="mainTitle" disablePadding sx={{ px: 2, mb: 5 }}>
        <ListSubheaderStyle

          sx={{

            ...(isCollapse && {
              opacity: 0,


            }),
          }}
        >
          {/* {group.subheader} */}
        </ListSubheaderStyle >
        {ListOfMenus.map((list) => (
          <NavListRoot className="profileTitle" key={list.title} list={list} isCollapse={isCollapse} />
        ))}
      </List>
    </Box>
  );
}
