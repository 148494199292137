import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  followResponse: [],
  unfollowResponse: [],
  followLoginRes: [],
  followingEmployersResponse: [],
  networkResponse: [],
  networkDeleteResponse: [],
  inviteStatusResponse: [],
  inviteResendStatusResponse: [],
  inviteEmailResponse: [],
  inviteSocialResponse: [],
  inviteSocialUpdateResponse: [],
  inviteBulkResponse: [],
  inviteBulkFailureResp: [],
  totalBulkFailureResp: [],
  followEmployersResponse: [],
  UnfollowResponse: [],
  followTalentResponse: [],
  followEmployerResponse: [],
  unfollowTalentResponse: [],
  unfollowEmployerResponse: [],
  followBackRes: [],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getFollowRes(state, action) {
      state.isLoading = false;
      state.followResponse = action.payload;
    },
    getUnFollowRes(state, action) {
      state.isLoading = false;
      state.unfollowResponse = action.payload;
    },
    FollowBackRes(state, action) {
      state.isLoading = false;
      state.followBackRes = action.payload;
    },
    FollowingEmployersRes(state, action) {
      state.isLoading = false;
      state.followingEmployersResponse = action.payload;
    },
    MyNetworkRes(state, action) {
      state.isLoading = false;
      state.networkResponse = action.payload;
    },
    MyNetworkDeleteRes(state, action) {
      state.isLoading = false;
      state.networkDeleteResponse = action.payload;
    },
    InviteStatusSuccess(state, action) {
      state.isLoading = false;
      state.inviteStatusResponse = action.payload;
    },
    InviteStatusResendSuccess(state, action) {
      state.isLoading = false;
      state.inviteResendStatusResponse = action.payload;
    },
    InviteEmailSuccess(state, action) {
      state.isLoading = false;
      state.inviteEmailResponse = action.payload;
    },
    InviteSocialSuccess(state, action) {
      state.isLoading = false;
      state.inviteSocialResponse = action.payload;
    },
    InviteSocialUpdateSuccess(state, action) {
      state.isLoading = false;
      state.inviteSocialUpdateResponse = action.payload;
    },
    InviteBulkSuccess(state, action) {
      state.isLoading = false;
      state.inviteBulkResponse = action.payload;
    },
    InviteBulkFailure(state, action) {
      state.isLoading = false;
      state.inviteBulkFailureResp = action.payload;
    },
    TotalBulkFailure(state, action) {
      state.isLoading = false;
      state.totalBulkFailureResp = action.payload;
    },
    FollowEmployersRes(state, action) {
      state.isLoading = false;
      state.followEmployersResponse = action.payload;
    },
    setFollowLogin(state, action) {
      state.isLoading = false;
      state.followLoginRes = action.payload;
    },
    UnFollowEmployersFollowing(state, action) {
      state.isLoading = false;
      state.UnfollowResponse = action.payload;
    },
    getTalentFollowRes(state, action) {
      state.isLoading = false;
      state.followTalentResponse = action.payload;
    },
    getTalentUnFollowRes(state, action) {
      state.isLoading = false;
      state.unfollowTalentResponse = action.payload;
    },
    getEmployerFollowRes(state, action) {
      state.isLoading = false;
      state.followEmployerResponse = action.payload;
    },
    getEmployerUnFollowRes(state, action) {
      state.isLoading = false;
      state.unfollowEmployerResponse = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function getFollowReq(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/following/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getFollowRes(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function ClearFollowReq(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getFollowRes([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUnFollowReq(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/unfollow/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getUnFollowRes(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function ClearUnFollowReq(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getUnFollowRes([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setFlagForLogin(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setFollowLogin(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getFollowingEmployersRes(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_following/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.FollowingEmployersRes(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}


export function getMyNetworks(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_employer_talents/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.MyNetworkRes(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getMyNetworksDelete(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/delete_employer_talents/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.MyNetworkDeleteRes(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}
export function getInviteStatus(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const tokenVal = window.localStorage.getItem("accessTokenTalent")
      const response = await axios.post('/get_invite_talents/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.InviteStatusSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getInviteStatusResend(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/invite_talents/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.InviteStatusResendSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}
export function getInviteEmail(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/invite_talents/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.InviteEmailSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getInviteSocial() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const tokenVal = window.localStorage.getItem("accessTokenTalent")
      const response = await axios.get('/get_agent_link/', tokenVal);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.InviteSocialSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}
export function getInviteSocialUpdate(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const tokenVal = window.localStorage.getItem("accessTokenTalent")
      const response = await axios.post('/update_agent_link/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.InviteSocialUpdateSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}
export function getInviteBulk(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {

      const response = await axios.post('/save_bulk_invitations/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.InviteBulkSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}
export function getInviteBulkfailure(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const tokenVal = window.localStorage.getItem("accessTokenTalent")
      const response = await axios.post('/get_failure_data/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.InviteBulkFailure(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}
export function totalBulkfailure(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const tokenVal = window.localStorage.getItem("accessTokenTalent")
      const response = await axios.post('/get_failure_data/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.TotalBulkFailure(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getFollowEmployersRes(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_followers/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.FollowEmployersRes(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function ClearFollowEmployersRes(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.FollowEmployersRes([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function setFollowingUnfollow(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/unfollow/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.UnFollowEmployersFollowing(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function ClearSetFollowingUnfollow(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.UnFollowEmployersFollowing([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function SendFollowBackReq(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/Employers_following/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.FollowBackRes(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}


export function ClearSendFollowBackReq(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.FollowBackRes([]));
    } catch (error) {
      dispatch(slice.actions.FollowBackRes(error));
    }
  };
}

export function getTalentFollowReq(data) {
  const TalentUrlApi = process.env.REACT_APP_HOST_API_KEY_TALENT;
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${data.token}`,
        },
        body: JSON.stringify({
          "following_id": data.following_id,
          "type": data.type,
          "enc_talent": data.enc_talent,
        })
      }
      // const fetchResponse = await fetch('http://43.204.70.133/api/talent/talent_following/', config);
      const fetchResponse = await fetch(`${TalentUrlApi}talent_following/`, config);
      const data1 = await fetchResponse.json();
      console.log(data1, '7u56767567', fetchResponse)
      if (data1 && data1.status === 200) {
        // if (data1 && data1.data && data1.data.status === 200) {
        dispatch(slice.actions.getTalentFollowRes(data1));
      } else {
        dispatch(slice.actions.hasError(data1.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getUnTalentFollowReq(data) {
  const TalentUrlApi = process.env.REACT_APP_HOST_API_KEY_TALENT;
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${data.token}`,
        },
        body: JSON.stringify({
          "following_id": data.following_id,
          "type": data.type,
          "enc_talent": data.enc_talent,
        })
      }
      // const fetchResponse = await fetch('http://43.204.70.133/api/talent/talent_following/', config);
      const fetchResponse = await fetch(`${TalentUrlApi}unfollow/`, config);
      const data1 = await fetchResponse.json();
      console.log(data1, '7u56767567', fetchResponse)
      if (data1 && data1.status === 200) {
        // if (data1 && data1.data && data1.data.status === 200) {
        dispatch(slice.actions.getTalentUnFollowRes(data1));
      } else {
        dispatch(slice.actions.hasError(data1.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getEmployerFollowReq(data) {
  const EmployerUrlApi = process.env.REACT_APP_HOST_API_KEY_EMPLOYER;
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${data.token}`,
        },
        body: JSON.stringify({
          "following_id": data.following_id,
          "type": data.type,
          "enc_employer": data.enc_employer,
        })
      }
      // const fetchResponse = await fetch('http://43.204.70.133/api/talent/talent_following/', config);
      const fetchResponse = await fetch(`${EmployerUrlApi}following/`, config);
      // const fetchResponse = await fetch(`${TrainerUrlApi}talent_following/`, config);
      const data1 = await fetchResponse.json();
      console.log(data1, '7u56767567', fetchResponse)
      if (data1 && data1.status === 200) {
        // if (data1 && data1.data && data1.data.status === 200) {
        dispatch(slice.actions.getEmployerFollowRes(data1));
      } else {
        dispatch(slice.actions.hasError(data1.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getEmployerUnFollowReq(data) {
  const EmployerUrlApi = process.env.REACT_APP_HOST_API_KEY_EMPLOYER;
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${data.token}`,
        },
        body: JSON.stringify({
          "following_id": data.following_id,
          "type": data.type,
          "enc_employer": data.enc_employer,
        })
      }
      // const fetchResponse = await fetch('http://43.204.70.133/api/talent/talent_following/', config);
      const fetchResponse = await fetch(`${EmployerUrlApi}unfollow/`, config);
      // const fetchResponse = await fetch(`${TrainerUrlApi}talent_following/`, config);
      const data1 = await fetchResponse.json();
      console.log(data1, '7u56767567', fetchResponse)
      if (data1 && data1.status === 200) {
        // if (data1 && data1.data && data1.data.status === 200) {
        dispatch(slice.actions.getEmployerUnFollowRes(data1));
      } else {
        dispatch(slice.actions.hasError(data1.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}
// http://43.204.70.133/api/employer/following/
export function ClearFollowEmployerReq() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getEmployerFollowRes([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function ClearUnFollowEmployerReq() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getEmployerUnFollowRes([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// http://43.204.70.133/api/employer/following/
export function ClearFollowTalentReq() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getTalentFollowRes([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearUnFollowTalentReq() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getTalentUnFollowRes([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function ClearResendSuccess(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {

      dispatch(slice.actions.InviteStatusResendSuccess([]));
      dispatch(slice.actions.InviteSocialUpdateSuccess([]));
      dispatch(slice.actions.InviteEmailSuccess([]));
      dispatch(slice.actions.InviteBulkSuccess([]));


    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function ClearFailureData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.TotalBulkFailure([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function clearHasError(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.hasError([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
