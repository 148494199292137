import { useContext } from 'react';
import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import { useDropzone } from 'react-dropzone';

// @mui
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
//
import { Icon } from '@iconify/react';
import Image from '../Image';
import Iconify from '../Iconify';
import RejectionFiles from './RejectionFiles';
import Logo from '../../images/avatar.jpg';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    // width: 117,
    // height: 117,
    width: 138,
    height: 136,
    margin: 'auto',
    borderRadius: '50%',
    padding: theme.spacing(1),
    border: `1px solid #b8aefb`,
}));

const DropZoneStyle = styled('div')({
    zIndex: 0,
    // width: '100px',
    // height: '100px',
    width: '120px',
    height: '120px',
    outline: 'none',
    display: 'flex',
    overflow: 'hidden',
    borderRadius: '50%',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    '& > *': { width: '100%', height: '100%' },
    '&:hover': {
        cursor: 'pointer',
        '& .placeholder': {
            zIndex: 9,
        },
    },
});



// ----------------------------------------------------------------------

UploadAvatatFaculty.propTypes = {
    error: PropTypes.bool,
    file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    helperText: PropTypes.node,
    sx: PropTypes.object,
};

export default function UploadAvatatFaculty({ error, file, helperText, sx, ...other }) {

    const PlaceholderStyle = styled('div')(({ theme }) => ({
        display: 'flex',
        position: 'absolute',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        height: "100%",
        color: theme.palette.text.secondary,
        // backgroundColor: theme.palette.background.neutral,
        backgroundColor: 'grey',
        transition: theme.transitions.create('opacity', {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
        }),
        // "&::before": {

        //   width:40,
        //   height:40,
        // },
        '&:hover': { opacity: 0.72 },
    }));
    const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
        multiple: false,
        ...other,
    });

    const fileCon = file ? null : `url(${Logo})`;


    return (
        <>
            <RootStyle
                sx={{
                    ...((isDragReject || error) && {
                        borderColor: 'error.light',
                    }),
                    ...sx,
                }}
            >
                <DropZoneStyle
                    className='ProfileImg'
                    {...getRootProps()}
                    sx={{
                        ...(isDragActive && { opacity: 0.72 }),
                    }}
                >
                    <input {...getInputProps()} />

                    {file && <Image alt="avatar" src={isString(file) ? file : file.preview} sx={{ zIndex: 8 }} />}

                    <PlaceholderStyle
                        className="placeholder"
                        sx={{
                            backgroundRepeat: "no-repeat",
                            // backgroundImage:`url(${Logo})`,
                            // backgroundImage: fileCon,
                            ...(file && {
                                opacity: 0,
                                color: 'common.white',
                                bgcolor: 'grey.900',
                                '&:hover': { opacity: 0.72 },
                            }),
                            ...((isDragReject || error) && {
                                bgcolor: 'error.lighter',
                            }),
                        }}
                    >
                        {/* <Iconify icon={'ic:outline-add'} sx={{ width: 24, height: 24, mb: 1,color }} /> */}
                        <Icon icon="ic:outline-add" color="white" width="30" height="30" />
                        {/* <Typography variant="caption">{file ? 'Change' : 'Upload photo'}</Typography> */}

                    </PlaceholderStyle>
                </DropZoneStyle>
            </RootStyle>

            {helperText && helperText}

            {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}
        </>
    );
}
