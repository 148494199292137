import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
// @mui
import { MenuItem, IconButton,Tooltip } from '@mui/material';
import MenuPopover from '../../../../components/MenuPopover';
import Iconify from '../../../../components/Iconify';
// ----------------------------------------------------------------------

UserMoreMenuServiceProfile.propTypes = {
  onDelete: PropTypes.func,
  userName: PropTypes.string,
};

export default function UserMoreMenuServiceProfile({ onDelete, onEdit, userName, editModalClose, }) {
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  useEffect(() => {
    if (editModalClose === true) {
      handleClose()
    }
  }, [editModalClose])
  const ICON = {
    mr: 2,
    width: 20,
    height: 20,
  };

  const handleEditUser = () => {
    onEdit()
    handleClose()
  }

  const handleDeleteUser = () => {
    onDelete()
    handleClose()
  }

  return (
    <>
      <Tooltip title="Menu" >
        <IconButton onClick={handleOpen}>
          <Iconify icon={'eva:more-vertical-fill'} width={20} height={20} />
        </IconButton>
      </Tooltip>


      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        arrow="right-top"
        sx={{
          mt: -1,
          width: 160,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <MenuItem onClick={handleDeleteUser} sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ ...ICON }} />
          Delete
        </MenuItem>

        <MenuItem onClick={handleEditUser} >
          <Iconify icon={'eva:edit-fill'} sx={{ ...ICON }} />
          Edit
        </MenuItem>
      </MenuPopover>
    </>
  );
}
