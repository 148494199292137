// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
};

const navConfig = [



  // MANAGEMENT
  // ----------------------------------------------------------------------

  {
    // subheader: 'management',
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.app, icon: ICONS.dashboard },
      { title: 'Profile', path: PATH_DASHBOARD.general.profile },
      // { title: 'Search', path: PATH_DASHBOARD.search.talentSearch },
      { title: 'Institution', path: PATH_DASHBOARD.institution },
      { title: 'Placements', path: PATH_DASHBOARD.placement },
      // { title: 'Jobs', path: PATH_DASHBOARD.jobs.postings, },
      { title: 'Jobs', path: PATH_DASHBOARD.search.root, },
      { title: 'Inbox', path: PATH_DASHBOARD.inBox.root },
      { title: 'Website', path: PATH_DASHBOARD.website },
      {
        title: 'Connect', path: PATH_DASHBOARD.connectnetwork.root,
      },
      {
        title: 'Network',
        path: PATH_DASHBOARD.network.root,
        icon: ICONS.user,
        children: [
          { title: 'Followers', path: PATH_DASHBOARD.network.followers },
          { title: 'Following', path: PATH_DASHBOARD.network.following },
        ],
      },
      { title: 'Settings', path: PATH_DASHBOARD.setting },
      // { title: 'Subscription', path: PATH_DASHBOARD.subscription },
    ],
  }

];

export default navConfig;
