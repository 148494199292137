import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, MenuItem, Link } from '@mui/material';
// routes
import { PATH_DASHBOARD, PATH_AUTH, PATH_PROFILE } from '../../../routes/paths';
import { useDispatch, useSelector } from '../../../redux/store';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import MyAvatar from '../../../components/MyAvatar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import { cleardata, clearDashboarddata, employerLogout, clearEmployerdata } from '../../../redux/slices/createProfile';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    linkTo: '/',
  },
  {
    label: 'Profile',
    linkTo: PATH_DASHBOARD.user.profile,
  },
  {
    label: 'Settings',
    linkTo: PATH_DASHBOARD.user.account,
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentUser, employerData, profileData } = useSelector((state) => {
    return {
      currentUser: state && state.profile && state.profile.profileImage,
      employerData: state && state.profile && state.profile.dashboardDataSucc && state.profile.dashboardDataSucc.response && state.profile.dashboardDataSucc.response.profile,
      profileData: state && state.profile && state.profile.events && state.profile.events.response && state.profile.events.response.get_employer_data,
    };
  });


  console.log(employerData, 'employerDataemployerData', employerData && employerData.icon_image);

  const { user, logout } = useAuth();
  const [updateProfile, setUpdateProfile] = useState([])
  const [disName, setDisName] = useState("");
  const [companyType, setCompanyType] = useState("");
  const isMountedRef = useIsMountedRef();

  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    try {
      const employerID = localStorage.getItem('userEmployerEncId');
      const payload = {
        "enc_id": employerID,
        "device_type": "web"
      };
      dispatch(employerLogout(payload))
      await logout();
      navigate(PATH_AUTH && PATH_AUTH.login, { replace: true });
      dispatch(cleardata());
      dispatch(clearDashboarddata());
      dispatch(clearEmployerdata());

      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  useEffect(() => {
    if (currentUser !== "") {
      setTimeout(() => {
        const userPhotourl = localStorage.getItem('userPhotourlEmp');
        setUpdateProfile(userPhotourl)
      }, 1000);
    }
  }, [currentUser])

  console.log(currentUser, 'currentUsercurrentUser')

  useEffect(() => {
    if (employerData && employerData !== "" || employerData !== undefined) {
      setDisName(employerData && employerData.slug)
      setCompanyType(employerData && employerData.company_type)
      if (employerData.icon_image && employerData.icon_image !== '' && employerData.icon_image !== null && employerData.icon_image !== undefined) {
        const dateandtime = new Date().toISOString();
        const imgUrl = employerData.icon_image
        const avtarUrlWithDatetimeicon = `${imgUrl}?datetime=${encodeURIComponent(dateandtime)}`
        localStorage.setItem('userPhotourlEmp', avtarUrlWithDatetimeicon);
        setUpdateProfile(avtarUrlWithDatetimeicon);
      }
    }
  }, [employerData])

  useEffect(() => {
    if (profileData && profileData !== "" || profileData !== undefined) {
      setDisName(profileData && profileData.slug)
      setCompanyType(profileData && profileData.company_type)
    }
  }, [profileData])

  // useEffect(() => {
  //   if (profileData && profileData !== "" || profileData !== undefined) {
  //     setCompanyType(profileData && profileData.company_type)
  //   }
  // }, [profileData])

  console.log(disName, '40df');

  const userSlug = localStorage.getItem('userSlug');

  const userIcon = localStorage.getItem('userIcon');

  useEffect(() => {
    if (userIcon !== "") {
      setTimeout(() => {
        setUpdateProfile(userIcon)
      }, 1000);
    }
    if (userSlug !== "") {
      setTimeout(() => {
        setDisName(userSlug)
      }, 1000);
    }
  }, [userSlug, userIcon]);





  const [toPhoto, setToPhoto] = useState(PATH_PROFILE.locationsDetails.root);
  const [invitation, setInvitation] = useState(PATH_DASHBOARD.connectnetwork.myinvitation);
  console.log(`${toPhoto}/${disName}`, 'f5sd40f6ds4f65', PATH_PROFILE.locationsDetails, PATH_PROFILE.locationsDetails.root);
  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <MyAvatar updateProfile={updateProfile} />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        {/* <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box> */}
        {disName && disName !== '' ?
          <MenuItem >
            <Link component={RouterLink} to={`${toPhoto}/${disName}`} target='_blank'>
              Profile
            </Link>
          </MenuItem>
          : ""}

        {companyType && companyType !== 'Company' ?
          <MenuItem >
            <Link component={RouterLink} to={`${invitation}`} target='_blank'>
              Send Invitation
            </Link>
          </MenuItem>
          : ""}

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1, color: '#000' }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
