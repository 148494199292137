// ----------------------------------------------------------------------

// const breakpoints = {
//   values: {
//     xs: 0,
//     sm: 768,
//     md: 992,
//     lg: 1200,
//     xl: 1536
//   }
// };
const breakpoints = {
  values: {
    xs: 0,
    xxs: 600,
    sm: 768,
    md: 992,
    lg: 1200,
    xl: 1536,
    // xxl:2000,
    xxl: 1900,
    xxxl: 2500,
  }
};

export default breakpoints;
