import React from 'react'
import { Grid, Box, Card, Table, Stack, CardContent, } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Page from '../Page'


const LeftPanel = () => {
    return (
        <Page title="LeftPanel Skeleton">
            <Box >
                <Box mt={11} p={1}>
                    <Skeleton animation="wave" variant="rounded" sx={{ backgroundColor: "#e4e4e4", height: '5rem', width: '100%', borderRadius: '1rem' }} />
                </Box>
                <Skeleton animation="wave" variant="rounded" sx={{ backgroundColor: "#e4e4e4", height: '100vh', width: '100%' }} />
            </Box>
        </Page>
    )
}

export default LeftPanel