import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    notificationData: [],
    notificationJobData: [],
    SingleJobNotification: [],
    enquiryData: [],
    inboxData: [],
    inboxNotiData: [],
    deleteEnquiryData: [],
    EnquiryLikeData: [],
    SendReplyData: [],
    deleteNotificationResData: [],
    getJobsGetData: [],
    deleteJobMsgResp: [],
    notificationReplyJob: '',
    isOpenModal: false,
    selectedEventId: null,
    selectedRange: null,
};

const slice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET EVENTS   
        TalentActivitySucc(state, action) {
            state.isLoading = false;
            state.getactivitylog = action.payload;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function getTalentActivityData(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/get_trainer_activity/', data);
            if (response && response.data && response.data.status === 200) {
                dispatch(slice.actions.TalentActivitySucc(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data));
            }
        } catch (error) {
            if (error && error.status !== '' && error.status !== undefined) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
            }
        }
    };
}

export function ClearTalentActivityClear(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.TalentActivitySucc([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function clearHasError(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.hasError([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

