// ----------------------------------------------------------------------
function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '';
const ROOTS_DASHBOARD = '';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  resetConfirmPassword: path(ROOTS_AUTH, '/reset-confirm-password'),
  verify: path(ROOTS_AUTH, '/verify'),
  userDone: path(ROOTS_AUTH, '/user-done'),
};
export const PATH_PROFILE = {
  // locationsDetails: ROOTS_DASHBOARD,
  prevProj: path(ROOTS_AUTH, '/project-preview'),
  locationsDetails: {
    root: path(ROOTS_DASHBOARD, ''),
    department: path(ROOTS_DASHBOARD, '/department'),
    courses: path(ROOTS_DASHBOARD, '/courses'),
    admission: path(ROOTS_DASHBOARD, '/admission'),
    contact: path(ROOTS_DASHBOARD, '/contact'),
    placement: path(ROOTS_DASHBOARD, '/placement'),
    faculty: path(ROOTS_DASHBOARD, '/faculty'),
    gallery: path(ROOTS_DASHBOARD, '/gallery'),
    // detailedCourse: path(ROOTS_DASHBOARD, '/courses/courseId'),
  },
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    profile: path(ROOTS_DASHBOARD, '/profile'),
  },
  app: path(ROOTS_DASHBOARD, '/dashboard'),
  // setting: path(ROOTS_DASHBOARD, '/setting'),
  // institution: path(ROOTS_DASHBOARD, '/institution'),
  activityLog: path(ROOTS_DASHBOARD, '/activityLog'),
  // InstitutionDashboard: path(ROOTS_DASHBOARD, '/InstitutionDashboard'),

  // placement: path(ROOTS_DASHBOARD, '/placement'),
  jobsearch: path(ROOTS_DASHBOARD, '/jobs'),
  jobsearchPage: path(ROOTS_DASHBOARD, '/jobs/search'),
  calender: path(ROOTS_DASHBOARD, '/calender'),
  // inBox: {
  //   root: path(ROOTS_DASHBOARD, '/inbox'),
  //   inbox: path(ROOTS_DASHBOARD, '/inbox/inbox'),
  //   notification: path(ROOTS_DASHBOARD, '/inbox/notification'),
  //   enquiry: path(ROOTS_DASHBOARD, '/inbox/enquiry'),
  // },
  connectnetwork: {
    root: path(ROOTS_DASHBOARD, '/connectnetwork'),
  },
  network: {
    root: path(ROOTS_DASHBOARD, '/network'),
    followers: path(ROOTS_DASHBOARD, '/network/followers'),
    following: path(ROOTS_DASHBOARD, '/network/following'),
  },

  inBox: {
    root: path(ROOTS_DASHBOARD, '/inbox'),
    talent: path(ROOTS_DASHBOARD, '/inbox/talent'),
    employer: path(ROOTS_DASHBOARD, '/inbox/talent/employer'),

    notification: path(ROOTS_DASHBOARD, '/inbox/talent/notification'),
    enquiry: path(ROOTS_DASHBOARD, '/inbox/talent/enquiry'),
  },
  subscription: path(ROOTS_DASHBOARD, '/subscription'),
  // website: path(ROOTS_DASHBOARD, '/website'),
  website: {
    root: path(ROOTS_DASHBOARD, '/website'),
    about: path(ROOTS_DASHBOARD, '/website/aboutus'),
    banners: path(ROOTS_DASHBOARD, '/website/banners'),
    services: path(ROOTS_DASHBOARD, '/website/services'),
    theme: path(ROOTS_DASHBOARD, '/website/theme'),
    setting: path(ROOTS_DASHBOARD, '/website/setting'),
    enquiry: path(ROOTS_DASHBOARD, '/website/enquiry'),
    gallery: path(ROOTS_DASHBOARD, '/website/gallery'),
    galleryImageUpload: path(ROOTS_DASHBOARD, '/website/gallery/image-upload'),
    galleryAlbumView: path(ROOTS_DASHBOARD, '/website/gallery/album-view'),
  },
  networking: path(ROOTS_DASHBOARD, '/networking'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  jobs: {
    root: path(ROOTS_DASHBOARD, '/jobs'),
    postings: path(ROOTS_DASHBOARD, '/jobs/postings'),
    create: path(ROOTS_DASHBOARD, '/jobs/create'),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/personal-info'),
  },
  search: {
    root: path(ROOTS_DASHBOARD, '/search'),
    talentSearch: path(ROOTS_DASHBOARD, '/search/talentSearch'),
    talentSearchResult: path(ROOTS_DASHBOARD, '/search/talentSearchResult'),
    talentSearchMain: path(ROOTS_DASHBOARD, '/search/talentSearchMain'),
  },

  setting: {
    root: path(ROOTS_DASHBOARD, '/setting'),
    profile: path(ROOTS_DASHBOARD, '/setting/insProfile'),
    mea_category: path(ROOTS_DASHBOARD, '/setting/mea_category'),
    account: path(ROOTS_DASHBOARD, '/setting/account'),
    notification: path(ROOTS_DASHBOARD, '/setting/notification'),
    profileVisibility: path(ROOTS_DASHBOARD, '/setting/profileVisibility'),
    manageAccount: path(ROOTS_DASHBOARD, '/setting/manageAccount'),
    deleteAccount: path(ROOTS_DASHBOARD, '/setting/deleteAccount'),
    subscriptionPlans: path(ROOTS_DASHBOARD, '/setting/subscription'),
    // subscriptionPlans: path(ROOTS_DASHBOARD, '/setting/subscription/plans'),
    // subscriptionTable: path(ROOTS_DASHBOARD, '/setting/subscription/subscriptionTable'),
    billingInfo: path(ROOTS_DASHBOARD, '/setting/subscription/billingInfo'),
    invoiceDetails: path(ROOTS_DASHBOARD, '/setting/subscription/invoiceDetails'),
    payment: path(ROOTS_DASHBOARD, '/setting/payment'),
  },

  institution: {
    root: path(ROOTS_DASHBOARD, '/institution'),
    courses: path(ROOTS_DASHBOARD, '/institution/courses'),
    faculty: path(ROOTS_DASHBOARD, '/institution/faculty'),
    admission: path(ROOTS_DASHBOARD, '/institution/admission'),
    institutionDashboard: path(ROOTS_DASHBOARD, '/institution/institutionDashboard'),
  },

  placement: {
    root: path(ROOTS_DASHBOARD, '/placement'),
    incharge: path(ROOTS_DASHBOARD, '/placement/incharge'),
    studentdatabase: path(ROOTS_DASHBOARD, '/placement/student-database'),
    studentdetails: path(ROOTS_DASHBOARD, '/placement/student-details'),
  },

};


export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
