import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, Alert, IconButton, InputAdornment, Box, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { useDispatch, useSelector } from '../../../redux/store';
import { setFlagForLogin } from '../../../redux/slices/Followers';
import { PATH_AUTH, PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
// ----------------------------------------------------------------------

export default function LoginForm() {
  const { login } = useAuth();
  const navigate = useNavigate();
  const isMountedRef = useIsMountedRef();
  const dispatch = useDispatch()
  const [showPassword, setShowPassword] = useState(false);

  // const LoginSchema = Yup.object().shape({
  //   username: Yup.string().required('Username is required'),
  //   password: Yup.string()
  //   .required('Password is required').matches(/^\S*$/, 'Whitespace is not allowed').min(6, 'Password length should be a minimum of 6 characters')
  //   .max(24, 'Password Must be less than 24 characters'),
  // });

  // const usernameSchema = Yup.string().test(
  //   'username',
  //   'Invalid Email or Phone number',
  //   function (value) {
  //     if (!value) {
  //       return false;
  //     }
  //     const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  //     const mobileRegex = /^[0-9]{10}$/;

  //     if (emailRegex.test(value) || mobileRegex.test(value)) {
  //       return true;
  //     }

  //     return false;
  //   }
  // );



  const LoginSchema = Yup.object().shape({
    // username: usernameSchema.required('Email or Phone number is required'),
    username: Yup.string().required('Username is required').min(7, 'Enter valid username').max(50, 'must be less than 50 characters'),
    password: Yup.string()
      .required("Password is required")
      .min(6, 'Password length should be a minimum of 6 characters')
      .max(24, 'Password Must be less than 24 characters')
      .matches(/^\S*(\s\S*)?$/, 'Whitespace is not allowed.'),
  });

  const { LoginFlag } = useSelector((state) => {
    return {
      LoginFlag: state && state.Follow && state.Follow.followLoginRes
    }
  });

  const defaultValues = {
    username: '',
    password: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    trigger,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      await login(data.username, data.password);
      if (LoginFlag === true) {
        navigate(-1)
        dispatch(setFlagForLogin([]))
      }
      if (LoginFlag.length === 0) {
        // navigate(PATH_DASHBOARD.general.profile, { replace: true });
        navigate(PATH_DASHBOARD.general.app, { replace: true });
      }
    } catch (error) {
      console.error(error);
      if ((error && error.status !== "") && (error.status !== undefined)) {
        setError('afterSubmit', error);
      } else {
        setError('afterSubmit', { status: "", message: "Something went wrong" });
      }
    }
  };
  const onHandleError = async (e) => {
    await trigger(e.target.name);
  };

  return (





    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Box spacing={2}>
          <Box className='loginMessage' >
            {!!errors.afterSubmit && <Alert sx={{ fontSize: { xs: '12px', sm: '15px' } }} className='alertMessage' severity="error">{errors.afterSubmit.message}</Alert>}
          </Box>
          <Box mt={2}>
            <Box>
              <RHFTextField className="loginInputHolder" name="username" label="Email or Phone Number" onBlur={e => onHandleError(e)} autocomplete="off" />
            </Box>
            <Box mt={2} mb={3.5}>
              <RHFTextField
                className="loginInputHolder loginInputHolders"
                name="password"
                label="Password"
                onBlur={e => onHandleError(e)}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box mt={2}>
              <LoadingButton className="btnCustom" fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                Login
              </LoadingButton>
            </Box>
          </Box>
          <Box mt={2} sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: { xs: 'wrap', sm: 'nowrap', rowGap: '5px' } }}>
            {/* <Grid container spacing={2}>
              <Grid item xs={12} md={8}> */}
            <Box>

              <Link component={RouterLink} to={`${PATH_AUTH.register}`}>
                New to TalentKind? Join now!
              </Link>
            </Box>

            {/* </Grid> */}
            {/* <Grid item xs={12} md={4}> */}
            <Box>
              <Link component={RouterLink} to={PATH_AUTH.resetPassword}>
                Forgot password?
              </Link>
            </Box>
            {/* </Grid>
            </Grid> */}
          </Box>
        </Box>
      </Box>
    </FormProvider>
  );
}
