import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  events: [],
  statess: [],
  cities: [],
  account: [],
  accountDeleteCheck: [],
  updateSettingProf: [],
  updareAccount: [],
  notification: [],
  updateNotification: [],
  updateChangePassword: [],
  deActivate: [],
  deleteAccount: [],
  profileVisibility: [],
  updateProfileVisibility: [],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
  updareEmail: [],
  updareMobile: [],
  AccountTalentHelp: [],
  sessionLogoutProfile: '',
  sessionLogoutMobile: '',
  resendOtpProfile: '',
  resendOtpEmail: '',
  ForgotResendResp: [],
  updareVerifyOtpSettings: [],
  verifyMobileOtpSettings: [],
  accountSummaryCount: [],
  verifyPasswordMobileOtpSettings: [],
  changepasswordSettingsResp: [],
  OtpSendChangeAccountStatusResp: [],
  // getNotificationSuccess:[]
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    profileVisibilityUpdateSuccess(state, action) {
      state.isLoading = false;
      state.updateProfileVisibility = action.payload;
    },

    deleteAccountUpdateSuccess(state, action) {
      state.isLoading = false;
      state.deleteAccount = action.payload;
    },

    deActivateUpdateSuccess(state, action) {
      state.isLoading = false;
      state.deActivate = action.payload;
    },

    changePasswordUpdateSuccess(state, action) {
      state.isLoading = false;
      state.updateChangePassword = action.payload;
    },

    notificationUpdateSuccess(state, action) {
      state.isLoading = false;
      state.updateNotification = action.payload;
    },

    getNotificationSuccess(state, action) {
      state.isLoading = false;
      state.notification = action.payload;
    },

    getProfileVisibilitySuccess(state, action) {
      state.isLoading = false;
      state.profileVisibility = action.payload;
    },

    getAccountSuccess(state, action) {
      state.isLoading = false;
      state.account = action.payload;
    },

    AccounttalenthelpSuccess(state, action) {
      state.isLoading = false;
      state.AccountTalentHelp = action.payload;
    },

    getAccountDeleteSuccess(state, action) {
      state.isLoading = false;
      state.accountDeleteCheck = action.payload;
    },
    getSettingProfileSuccess(state, action) {
      state.isLoading = false;
      state.updateSettingProf = action.payload;
    },
    accountUpdateSuccess(state, action) {
      state.isLoading = false;
      state.updareAccount = action.payload;
    },
    getEventsSuccess(state, action) {
      state.isLoading = false;
      state.events = action.payload;
    },
    getStateSuccess(state, action) {
      state.isLoading = false;
      state.statess = action.payload;
    },
    getCitySuccess(state, action) {
      state.isLoading = false;
      state.cities = action.payload;
    },
    ProfileUpdateSuccess(state, action) {
      state.isLoading = false;
      state.cities = action.payload;
    },
    verifyOtpSettingsSuccess(state, action) {
      state.isLoading = false;
      state.updareVerifyOtpSettings = action.payload;
    },
    verifyMobileOtpSettingsSuccess(state, action) {
      state.isLoading = false;
      state.verifyMobileOtpSettings = action.payload;
    },

    emailUpdateSuccess(state, action) {
      state.isLoading = false;
      state.updareEmail = action.payload;
    },
    mobileUpdateSuccess(state, action) {
      state.isLoading = false;
      state.updareMobile = action.payload;
    },
    sessionLogoutSuccess(state, action) {
      state.isLoading = false;
      state.sessionLogoutProfile = action.payload;
    },
    sessionLogoutMobileSuccess(state, action) {
      state.isLoading = false;
      state.sessionLogoutMobile = action.payload;
    },
    resendOtpSettingsSuccess(state, action) {
      state.isLoading = false;
      state.resendOtpProfile = action.payload;
    },
    resendOtpSettingsDataSuccess(state, action) {
      state.isLoading = false;
      state.resendOtpEmail = action.payload;
    },

    ForgotResendSuccess(state, action) {
      state.isLoading = false;
      state.ForgotResendResp = action.payload;
    },

    // CREATE EVENT
    createEventSuccess(state, action) {
      const newEvent = action.payload;
      state.isLoading = false;
      state.events = [...state.events, newEvent];
    },

    // UPDATE EVENT
    updateEventSuccess(state, action) {
      const event = action.payload;
      const updateEvent = state.events.map((_event) => {
        if (_event.id === event.id) {
          return event;
        }
        return _event;
      });

      state.isLoading = false;
      state.events = updateEvent;
    },

    // DELETE EVENT
    deleteEventSuccess(state, action) {
      const { eventId } = action.payload;
      const deleteEvent = state.events.filter((event) => event.id !== eventId);
      state.events = deleteEvent;
    },

    // SELECT EVENT
    selectEvent(state, action) {
      const eventId = action.payload;
      state.isOpenModal = true;
      state.selectedEventId = eventId;
    },

    // SELECT RANGE
    selectRange(state, action) {
      const { start, end } = action.payload;
      state.isOpenModal = true;
      state.selectedRange = { start, end };
    },

    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },
    getsummaryCountSuccess(state, action) {
      state.isLoading = false;
      state.accountSummaryCount = action.payload;
    },

    verifyPasswordMobileOtpSettingsSuccess(state, action) {
      state.isLoading = false;
      state.verifyPasswordMobileOtpSettings = action.payload;
    },

    changepasswordSettingsSuccess(state, action) {
      state.isLoading = false;
      state.changepasswordSettingsResp = action.payload;
    },
    OtpSendChangeAccountStatusSuccess(state, action) {
      state.isLoading = false;
      state.OtpSendChangeAccountStatusResp = action.payload;
    },

  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function getProfileData() {
  const tokenVal = window.localStorage.getItem("accessToken")
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/personal_information/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization:
            `Bearer ${tokenVal}`,
        },
      });
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getEventsSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}




export function updateAccount(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/trainer_update_account/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.accountUpdateSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getAccountData() {

  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/trainer_get_account/');
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getAccountSuccess(response));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}


export function AccountTalentHelp() {

  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get_trainert_setting_help/');
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.AccounttalenthelpSuccess(response));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getAccountDeleteData() {

  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get_delete_account/');
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getAccountDeleteSuccess(response));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getSettingProfileData(data) {

  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/update_institution_details/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getSettingProfileSuccess(response));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function ClearLanguageIndustry(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.notificationUpdateSuccess([]));
      dispatch(slice.actions.profileVisibilityUpdateSuccess([]))
      dispatch(slice.actions.accountUpdateSuccess([]));
      dispatch(slice.actions.error([]));
      // dispatch(slice.actions.changePasswordUpdateSuccess([]));
      dispatch(slice.actions.deActivateUpdateSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getNotificationData() {

  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/trainer_get_notification/');
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getNotificationSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function updateNotification(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/trainer_update_notification/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.notificationUpdateSuccess(response));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}


export function updateDeActivate(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/trainer_change_status/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.deActivateUpdateSuccess(response));
      } else {
        dispatch(slice.actions.hasError(response.data))
      };
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function ClearUpdateDeActivate(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.deActivateUpdateSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateDeleteAccount(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/trainer_delete_account/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.deleteAccountUpdateSuccess(response));
      } else {
        dispatch(slice.actions.hasError(response.data))
      };
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function clearUpdateDeleteAccount(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.deleteAccountUpdateSuccess([]));
      dispatch(slice.actions.deActivateUpdateSuccess([]));
      dispatch(slice.actions.deActivateUpdateSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProfileVisibility() {

  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get_profile_visibility/');
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getProfileVisibilitySuccess(response));
      } else {
        dispatch(slice.actions.hasError(response.data))
      };
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}


export function updateProfileVisibility(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/trainer_update_profile_visibility/', data)
      dispatch(slice.actions.profileVisibilityUpdateSuccess(response));
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}


export function clearHasError(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.hasError([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// Profile OTP
export function resendOtpSettings(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/resend_otp_settings/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.resendOtpSettingsDataSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}
//  ////////////////////////////////////////////////////////////////////
export function ClearChangePassword(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.changepasswordSettingsSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ChangePasswordSettings(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/trainer_change_password_send_otp/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.changepasswordSettingsSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}


export function verifyPasswordMobileOtpSettings(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/verify_otp_settings/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.verifyPasswordMobileOtpSettingsSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function updateChangePassword(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/trainer_change_password/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.changePasswordUpdateSuccess(response))
      } else {
        dispatch(slice.actions.hasError(response.data))
      };
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}


export function ClearPassChanged(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.changePasswordUpdateSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//  /////////////////////////////////////////////////////////////////////////////////
export function ClearVerifyPasswordMobileOtp(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.verifyPasswordMobileOtpSettingsSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function ForgotResend(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/userResendOtp/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.ForgotResendSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function ClearResendOtpClear(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.resendOtpSettingsDataSuccess(''));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateEmailOtp(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/check_mobile_or_email_valid/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.emailUpdateSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function updateMobleOtp(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/check_mobile_or_email_valid/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.mobileUpdateSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}


export function clearUpdateAccount(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.emailUpdateSuccess([]));
      dispatch(slice.actions.mobileUpdateSuccess([]));
      dispatch(slice.actions.verifyOtpSettingsSuccess([]));
      dispatch(slice.actions.verifyMobileOtpSettingsSuccess([]));
      dispatch(slice.actions.sessionLogoutSuccess(''));
      dispatch(slice.actions.sessionLogoutMobileSuccess(''));
      // dispatch(slice.actions.sessionLogoutMobileSuccess(''));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function logoutProfilrSettings(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.sessionLogoutSuccess(data));
      // await logout();
      // navigate(PATH_AUTH && PATH_AUTH.login, { replace: true });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function logoutMobileSettings(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.sessionLogoutMobileSuccess(data));
      // await logout();
      // navigate(PATH_AUTH && PATH_AUTH.login, { replace: true });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function verifyEmailOtpSettings(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/verify_otp_settings/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.verifyOtpSettingsSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function clearverifyOtpSettings(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.verifyOtpSettingsSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function verifyMobileOtpSettings(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/verify_otp_settings/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.verifyMobileOtpSettingsSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function cleardata(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getSettingProfileSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// get employer summary count api calling
export function getAccountSummaryCount() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get_trainer_summary/');
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getsummaryCountSuccess(response));
        // console.log("responseresponse",response)
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function OtpSendChangeAccountStatus(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/statusotp_settings/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.OtpSendChangeAccountStatusSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}


export function ClearOtpSendChangeAccountStatus(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.OtpSendChangeAccountStatusSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function ClearVerifyMobileOtpSettings(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.verifyMobileOtpSettingsSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


