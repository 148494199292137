import React from 'react';
import { Box, Typography, Stack } from '@mui/material';
// assets
import { LoadingButton } from '@mui/lab';
import { UploadIllustration } from '../../assets';
import iconImage from '../../images/image-icon.png';

const AwardsBlock = () => {
  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent="center"
      direction={{ xs: 'column', md: 'column' }}
      sx={{ width: 1, textAlign: { xs: 'center', md: 'left' } }}
    >
      <Box
        component="img"
        sx={
          {
            // width: 350,
            maxHeight: { xs: 80, md: 80 },
            maxWidth: { xs: 93, md: 93 },
          }
        }
        alt="The house from the offer."
        src={iconImage}
      />
      {/* <img src={iconImage} alt="RecommendationsImage" sx={{ width: 100, height: 100 }} /> */}
      <Box sx={{ textAlign: 'center' }}>
        <Typography sx={{ mb: 0.75, color: '#44577a', fontSize: 14 }}>
          + Add Image <br /> Recommended Size 200 x 350 Pixels
        </Typography>
        <Box sx={{}}>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Allowed formats *.jpg ,*.jpeg,*.png<br />
              Max Image Size 3MB
            </Typography>
        </Box>
        {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Allowed formats *.jpg ,*.jpeg,*.png<br />
              Max Image Size 20MB
            </Typography> */}
      </Box>
    </Stack>
  );
};

export default AwardsBlock;
