import React from 'react';
import { Box, Typography, Stack } from '@mui/material';
// assets
import { LoadingButton } from '@mui/lab';
import { UploadIllustration } from '../../assets';
import iconImage from '../../images/image-icon.png';

const AddBlock = () => {
  return (
    <Stack
      spacing={1}
      alignItems="center"
      justifyContent="center"
      direction={{ xs: 'row', md: 'row' }}
      sx={{ width: 1, textAlign: { xs: 'center', md: 'left' } }}
    >
      <Box
        component="img"
        sx={
          {
            // width: 350,
            maxHeight: { xs: 50, md: 50 },
            maxWidth: { xs: 50, md: 50 },
          }
        }
        alt="The house from the offer."
        src={iconImage}
      />
      {/* <img src={iconImage} alt="RecommendationsImage" sx={{ width: 100, height: 100 }} /> */}
      <Box sx={{ textAlign: 'center' }}>
        <Typography sx={{ mb: 0.75, color: '#000000', fontSize: 14 }}>
          + Add 
        </Typography>
        <Box sx={{}}>
          {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Allowed formats *.jpg ,*.jpeg,*.png<br />
              Max Image Size 20MB
            </Typography> */}
        </Box>
        {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Allowed formats *.jpg ,*.jpeg,*.png<br />
              Max Image Size 20MB
            </Typography> */}
      </Box>
    </Stack>
  );
};

export default AddBlock;
