import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  events: [],
  statess: [],
  cities: [],
  profileUpdate: [],
  ImageIndex: [],
  profileUpdateSuccess: [],
  BannersWebSuccess: [],
  UpdateBannersWebSuccess: [],
  settingWebSuccess: [],
  profileEditSuccess: [],
  deleteProfileResp: [],
  settingDataSuccess: [],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
  verifycheckSlugResp: [],
  settingThemesSucc: [],
  SaveWebsiteThemesData: [],
  SetDefaultWebsiteThemesData: [],
  errorVerifySlug: [],
  ContactData: [],
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getEventsSuccess(state, action) {

      state.isLoading = false;
      state.events = action.payload;

    },

    ProfileUpdateSuccess(state, action) {
      state.isLoading = false;
      state.profileUpdate = action.payload;
    },
    setImageIndex(state, action) {
      state.isLoading = false;
      state.ImageIndex = action.payload;
    },
    ProfUpdateSuccess(state, action) {
      state.isLoading = false;
      state.profileUpdateSuccess = action.payload;
    },
    DeleteProfileSuccess(state, action) {
      state.isLoading = false;
      state.deleteProfileResp = action.payload;
    },
    ProfUpdateEditSuccess(state, action) {
      state.isLoading = false;
      state.profileEditSuccess = action.payload;
    },
    // Website 
    aboutUsWebsiteSuccess(state, action) {
      state.isLoading = false;
      state.aboutUsWebSuccess = action.payload;
    },
    UpdateaboutUsWebsiteSuccess(state, action) {
      state.isLoading = false;
      state.UpdateaboutUsWebSuccess = action.payload;
    },

    BannersWebsiteSuccess(state, action) {
      state.isLoading = false;
      state.BannersWebSuccess = action.payload;
    },
    getWebsiteThemeSuccess(state, action) {
      state.isLoading = false;
      state.settingThemesSucc = action.payload;
    },
    SaveWebsiteThemesSucc(state, action) {
      state.isLoading = false;
      state.SaveWebsiteThemesData = action.payload;
    },
    SetDefaultWebsiteThemesSucc(state, action) {
      state.isLoading = false;
      state.SetDefaultWebsiteThemesData = action.payload;
    },
    UpdateBannersWebsiteSuccess(state, action) {
      state.isLoading = false;
      state.UpdateBannersWebSuccess = action.payload;
    },
    getWebsiteSettingSuccess(state, action) {
      state.isLoading = false;
      state.settingWebSuccess = action.payload;
    },
    UpdateWebsiteSettingSuccess(state, action) {
      state.isLoading = false;
      state.settingDataSuccess = action.payload;
    },

    // CREATE EVENT
    createEventSuccess(state, action) {
      const newEvent = action.payload;
      state.isLoading = false;
      state.events = [...state.events, newEvent];
    },

    // UPDATE EVENT
    updateEventSuccess(state, action) {
      const event = action.payload;
      const updateEvent = state.events.map((_event) => {
        if (_event.id === event.id) {
          return event;
        }
        return _event;
      });

      state.isLoading = false;
      state.events = updateEvent;
    },
    verifycheckSlugSuccess(state, action) {
      state.isLoading = false;
      state.verifycheckSlugResp = action.payload;
    },
    hasErrorSave(state, action) {
      state.isLoading = false;
      state.errorVerifySlug = action.payload;
    },

    getContactSucc(state, action) {
      state.isLoading = false;
      state.ContactData = action.payload;
    },


  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function getProfileData() {
  const tokenVal = window.localStorage.getItem("accessToken")
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get_company_profile/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization:
            `Bearer ${tokenVal}`,
        },
      });
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getEventsSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function updateProfile(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/update_company_profile/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.ProfileUpdateSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}
export function updateProfileProfile(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/update_company_profile/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.ProfUpdateSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getaboutUsWebsite(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      // const response = await axios.get('/get_company_about_us/',data)
      const response = await axios.get('/get_institute_about_us/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.aboutUsWebsiteSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}
export function getBannersWebsite(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.get('/get_institute_banners/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.BannersWebsiteSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}


export function getSettingTheme(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.get('/get_website_themes/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getWebsiteThemeSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}


export function SaveWebsiteThemes(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/save_website_themes/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.SaveWebsiteThemesSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      if (error && error.status !== '' && error.status !== undefined) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
      }
    }
  };
}

export function SetDefaultWebsiteThemes(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/set_default_website_themes/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.SetDefaultWebsiteThemesSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      if (error && error.status !== '' && error.status !== undefined) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
      }
    }
  };
}



export function ClearSaveWebsiteThemes(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.SaveWebsiteThemesSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function ClearSetDefaultWebsiteThemes(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.SetDefaultWebsiteThemesSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}









export function UpdateBannersWebsite(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/update_institute_banners/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.UpdateBannersWebsiteSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}
export function UpdateaboutUsWebsite(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/update_institute_about_us/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.UpdateaboutUsWebsiteSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}
export function updateEditProfile(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/update_company_profile/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.ProfUpdateEditSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function deleteProfileData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/delete_institute_banners/', data)

      dispatch(slice.actions.DeleteProfileSuccess(response.data));

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getSettingWebsite(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.get('/get_website_settings/', data)
      console.log('asdfdfdf', response.data);

      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getWebsiteSettingSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}
export function UpdateSettingWebsite(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/update_website_settings/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.UpdateWebsiteSettingSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function ClearUpdateWebsiteSetting(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.UpdateWebsiteSettingSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function ClearUpdateProfileProfile(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.ProfUpdateSuccess([]));
      dispatch(slice.actions.DeleteProfileSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function ClearAboutusWeb(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.aboutUsWebsiteSuccess([]));
      dispatch(slice.actions.UpdateaboutUsWebsiteSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function ClearBannersWeb(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.BannersWebsiteSuccess([]));
      dispatch(slice.actions.UpdateBannersWebsiteSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearPostProfileData() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.ProfileUpdateSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setImageData(data) {
  return async () => {
    try {
      dispatch(slice.actions.setImageIndex(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearHasError(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.hasError([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function verifySlug(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/verify_slug/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.verifycheckSlugSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasErrorSave(error));
        dispatch(slice.actions.hasError(error))
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function clearVerifySlug() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.verifycheckSlugSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearVerifyError() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.hasErrorSave([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// export function getContactData() {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.get('/get_contact_us/');
//       if (response && response.data && response.data.status === 200) {
//         dispatch(slice.actions.getContactSucc(response.data));
//       } else {
//         dispatch(slice.actions.hasError(response.data))
//       }
//     } catch (error) {
//       if ((error && error.status !== "") && (error.status !== undefined)) {
//         dispatch(slice.actions.hasError(error));
//       } else {
//         dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
//       }
//     }
//   };
// }



export function getEnquiryData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_contact_us/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getContactSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasErrorSave(error));
        dispatch(slice.actions.hasError(error))
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}