import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Drawer, Card, Container, Grid, Typography, Button, DialogContentText, Dialog, DialogContent, List, ListItem, ListItemIcon, ListItemText, CardContent, Avatar, Tooltip } from '@mui/material';
import { CheckCircle, CheckCircleOutline } from '@mui/icons-material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { NAVBAR } from '../../../config';
import Scrollbar from '../../../components/Scrollbar';
import { NavSectionVertical } from '../../../components/nav-section';
// redux
import { useDispatch, useSelector } from '../../../redux/store';
//
import navConfig from './NavConfig';
import Logo from '../../../images/dummy_profile.png';
import HeadHunting from '../../../images/Girl_with_laptop.webp'
import LeftPanel from '../../../components/skeleton/LeftPanel'

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------
// Yash_raj_logo.png

NavbarVertical.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function NavbarVertical({ isOpenSidebar, onCloseSidebar }) {
  const theme = useTheme();
  const [selectedUser, setSelectedUser] = useState('');
  const [updateImg, setUpdateImg] = useState('');
  const { pathname } = useLocation();
  const [company_type, setCompany_type] = useState('');
  const [company_name, setCompany_name] = useState('');
  const [companyProfile, setCompanyProfile] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [companyTypeData, setCompanyTypeData] = useState({});

  const isDesktop = useResponsive('up', 'lg');

  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  const { currentUser, profileImageData, dashboardCompTabs, InsCompTabs, PlaceCompTabs, getWebsite, getAccountSetting } = useSelector((state) => {
    return {
      currentUser: state && state.profile && state.profile.events && state.profile.events.response,
      profileImageData: state && state.profile && state.profile.profileImage,
      dashboardCompTabs: state && state.profile && state.profile.dashboardDataSucc && state.profile.dashboardDataSucc && state.profile.dashboardDataSucc.response && state.profile.dashboardDataSucc.response.completion_tabs,
      InsCompTabs: state && state.InsCouse && state.InsCouse.courseData && state.InsCouse.courseData.response && state.InsCouse.courseData.response.completion_tabs,
      PlaceCompTabs: state && state.StudentDatabase && state && state.StudentDatabase.PlacementGetInchargeData && state && state.StudentDatabase.PlacementGetInchargeData.repsonse && state.StudentDatabase.PlacementGetInchargeData.repsonse.completion_tabs,
      getWebsite: state && state.companyprofile && state.companyprofile.aboutUsWebSuccess && state.companyprofile.aboutUsWebSuccess && state.companyprofile.aboutUsWebSuccess.response,
      getAccountSetting: state && state.settings && state.settings.account && state.settings.account.data && state.settings.account.data.response,

    };
  });

  console.log(currentUser, '05df65sd0f', companyProfile,);

  useEffect(() => {
    if (currentUser && currentUser !== undefined && currentUser.user) {
      setSelectedUser(currentUser.user);

      if (currentUser.user && currentUser.user.avatar_url) {
        setUpdateImg(currentUser.user.avatar_url);
      }
      if (currentUser.institutions_highlight && Object.keys(currentUser.institutions_highlight).length > 0) {
        setCompanyTypeData(currentUser.institutions_highlight);
      }
      console.log('running 9');
      if (currentUser.completion_tabs && currentUser.completion_tabs.length === 2) {
        console.log('running 96');
        setCompanyProfile("Yes");
        localStorage.setItem('companyProfileFilled', 'Yes');
      }
      if (currentUser.completion_tabs && currentUser.completion_tabs.length < 2) {
        console.log('running 96');
        setCompanyProfile("No");
        localStorage.setItem('companyProfileFilled', 'No');
      }
    }
  }, [currentUser]);

  console.log(company_name, '045fdgdf6g50f4');


  useEffect(() => {
    if (dashboardCompTabs && dashboardCompTabs !== undefined) {
      if (dashboardCompTabs && dashboardCompTabs.length === 2) {
        setCompanyProfile("Yes");
        localStorage.setItem('companyProfileFilled', 'Yes');
      }
    }
  }, [dashboardCompTabs]);


  useEffect(() => {
    if (InsCompTabs && InsCompTabs !== undefined) {
      if (InsCompTabs && InsCompTabs.length === 2) {
        setCompanyProfile("Yes");
        localStorage.setItem('companyProfileFilled', 'Yes');
      }
    }
  }, [InsCompTabs]);


  useEffect(() => {
    if (PlaceCompTabs && PlaceCompTabs !== undefined) {
      if (PlaceCompTabs && PlaceCompTabs.length === 2) {
        setCompanyProfile("Yes");
        localStorage.setItem('companyProfileFilled', 'Yes');
      }
    }
  }, [PlaceCompTabs]);

  useEffect(() => {
    if (getWebsite && getWebsite !== undefined) {
      if (getWebsite.completion_tabs && getWebsite.completion_tabs.length === 2) {
        setCompanyProfile("Yes");
        localStorage.setItem('companyProfileFilled', 'Yes');
      }
    }
  }, [getWebsite]);

  useEffect(() => {
    if (getAccountSetting && getAccountSetting !== undefined) {
      if (getAccountSetting.completion_tabs && getAccountSetting.completion_tabs.length === 2) {
        setCompanyProfile("Yes");
        localStorage.setItem('companyProfileFilled', 'Yes');
      }
    }
  }, [getAccountSetting]);


  const localcompanyProfileFilled = localStorage.getItem('companyProfileFilled');


  useEffect(() => {
    if (localcompanyProfileFilled && localcompanyProfileFilled !== "") {
      if (localcompanyProfileFilled === "Yes") {
        setCompanyProfile("Yes");
      }
    }
  }, [localcompanyProfileFilled]);

  console.log(localcompanyProfileFilled, '04d6fg4dfg4', localcompanyProfileFilled && localcompanyProfileFilled === 'Yes');

  useEffect(() => {
    if (currentUser !== "") {
      setTimeout(() => {
        const companyType = localStorage.getItem('companyType');
        const CompanyName = localStorage.getItem('CompanyName');
        // const CompanyFilledORNot = localStorage.getItem('companyProfileFilled');
        // if (CompanyFilledORNot && CompanyFilledORNot !== '' && CompanyFilledORNot !== undefined && CompanyFilledORNot !== null) {
        //   if (CompanyFilledORNot === 'Yes') {
        //     setCompanyProfile("Yes");
        //   }
        // }
        setCompany_type(companyType);
        setCompany_name(CompanyName);

      }, 1000);
    }
  }, [currentUser])
  useEffect(() => {
    if (profileImageData !== "") {
      setTimeout(() => {
        const userPhotourl = localStorage.getItem('userPhotourlEmp');
        setUpdateImg(userPhotourl)
      }, 1000);
    }
  }, [profileImageData])

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  console.log(company_name, 'company_name');


  const renderContent = (
    <Scrollbar
      className='sideBar'
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <NavSectionVertical navConfig={navConfig} isCollapse={isCollapse} IsProfile={companyProfile} insName={company_name} updateImg={updateImg} />

      <Box sx={{ flexGrow: 1 }} />

    </Scrollbar>
  );

  console.log(companyProfile, 'df0d4fgdf4g');


  useEffect(() => {
    if (companyProfile && companyProfile !== undefined) {
      setIsLoading(false)
    }
  }, [companyProfile])

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >


      {!isDesktop && (
        <>
          {companyProfile && companyProfile === 'Yes' ?
            <>
              <Drawer open={isOpenSidebar} onClose={onCloseSidebar} PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH, bgcolor: '#001892' } }}>
                {renderContent}
              </Drawer>
            </>
            :
            <Drawer open={isOpenSidebar} onClose={onCloseSidebar} PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH, bgcolor: '#001892' } }}>
              <Box style={{ backgroundColor: "#eef3fe", height: "100%", position: "relative" }}>
                <Box py={1} style={{ height: "100%" }}>
                  <Typography className='font-weight-bold' style={{ fontSize: "1rem" }} align='center'>{companyTypeData.name} </Typography>
                  <Box mt={2} style={{ display: "flex", justifyContent: "center" }}>
                    <img src={HeadHunting} alt="head hunting" className='img-fluid' style={{ width: '48%' }} />
                  </Box>
                  {console.log('rendered 2')}
                  <Box p={1}>
                    <List>
                      {companyTypeData && companyTypeData.length > 0 ? companyTypeData.map((row) => {
                        return (
                          <ListItem style={{ padding: "6px 16px", alignItems: 'start' }}>
                            <ListItemIcon style={{ marginRight: "0.5rem", marginTop: '2px' }}>
                              <CheckCircleOutline style={{ color: "#56bcab", fontSize: "1rem" }} />
                            </ListItemIcon>
                            <ListItemText className='leftPanelList' primary={row.name} style={{ color: "#000", fontWeight: 500, fontSize: '13px' }} />
                          </ListItem>
                        )
                      })
                        : ""}
                    </List>
                  </Box>
                </Box>
              </Box>
            </Drawer>
          }
        </>

      )}

      {isDesktop && (
        <>
          {isLoading ? (<LeftPanel />) : (

            <>
              {companyProfile && companyProfile === 'Yes' ?
                <>
                  <Drawer
                    open
                    variant="persistent"
                    onMouseEnter={onHoverEnter}
                    onMouseLeave={onHoverLeave}
                    PaperProps={{
                      sx: {
                        width: NAVBAR.DASHBOARD_WIDTH,
                        borderRightStyle: 'solid',
                        top: 80,
                        bgcolor: 'background.default',
                        transition: (theme) =>
                          theme.transitions.create('width', {
                            duration: theme.transitions.duration.standard,
                          }),
                        ...(isCollapse && {
                          width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
                        }),
                        ...(collapseHover && {
                          ...cssStyles(theme).bgBlur(),
                          boxShadow: (theme) => theme.customShadows.z24,
                        }),
                      },
                    }}
                  >
                    <Box sx={{ minHeight: '110px', backgroundColor: '#f7f6f9' }}>
                      <Card sx={{ m: 1, p: 1, marginTop: "15px", minHeight: "65px" }} className="isLogoBoxShadow">

                        <Grid container alignItems={'center'}>
                          <Grid item md={4}>
                            <Box >
                              <Avatar
                                alt="logo"
                                className='NavbarAvatar'
                                src={updateImg && updateImg !== '' ? updateImg : Logo}
                                sx={{ width: 60, height: 60 }}
                              />
                            </Box>
                            {console.log('rendered 1')}

                          </Grid>
                          <Grid item md={8} sx={{ mt: "7px" }}>
                            <Typography gutterBottom sx={{ fontSize: "13px", color: "#777777" }}>Institution Dashboard  </Typography>
                            {company_name && company_name.length > 18 ?
                              <Tooltip title={(company_name && company_name)} componentsProps={{
                                tooltip: {
                                  sx: {
                                    bgcolor: 'gray',
                                    padding: '8px',
                                    textTransform: 'capitalize',
                                  },
                                },
                              }} >
                                <Typography className='transform' sx={{ fontSize: "15px", color: "black", fontWeight: "600", cursor: 'pointer' }}>{company_name && company_name !== '' ?
                                  <span>{company_name.length > 18 ? company_name.substring(0, 18).concat("...") : company_name} </span> : 'Institution Name'} </Typography>
                              </Tooltip>

                              :
                              <Typography className='transform' sx={{ fontSize: "15px", color: "black", fontWeight: "600" }}>{company_name && company_name !== '' ?
                                company_name : 'Institution Name'} </Typography>
                            }

                          </Grid>
                        </Grid>
                      </Card>
                    </Box>
                    {renderContent}

                  </Drawer>
                </>
                :
                <Drawer
                  open
                  variant="persistent"
                  onMouseEnter={onHoverEnter}
                  onMouseLeave={onHoverLeave}
                  PaperProps={{
                    sx: {
                      width: NAVBAR.DASHBOARD_WIDTH,
                      borderRightStyle: 'solid',
                      top: 80,
                      // bgcolor: 'background.default',
                      transition: (theme) =>
                        theme.transitions.create('width', {
                          duration: theme.transitions.duration.standard,
                        }),
                      ...(isCollapse && {
                        width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
                      }),
                      ...(collapseHover && {
                        ...cssStyles(theme).bgBlur(),
                        boxShadow: (theme) => theme.customShadows.z24,
                      }),
                    },
                  }}>
                  <Box sx={{ minHeight: '110px', backgroundColor: '#f7f6f9' }}>
                    <Card sx={{ m: 1, mt: 2, p: 1, mb: 0, pr: 5, minHeight: "80px" }} className="isLogoBoxShadow">
                      <Grid container md={12}>
                        <Grid item md={4}>
                          <Box sx={{ ml: 2, mt: 1.5 }}>
                            {console.log(updateImg, 'updateImg')}
                            <img
                              src={updateImg && updateImg !== '' ? updateImg : Logo}
                              alt="logo" style={{ height: "40px", textAlign: 'center', borderRadius: "50px", p: 1 }} />
                          </Box>
                        </Grid>
                        <Grid item md={8} sx={{ mt: 1 }}>
                          <Typography sx={{ fontSize: "11px", color: "#777777" }}>Institution Dashboard </Typography>
                          {/* <Typography sx={{ fontSize: "14px", color: "#777777", fontWeight: "bold" }}>{company_name && company_name !== '' ?
                            <span>{company_name.length > 18 ? company_name.substring(0, 18).concat("...") : company_name} </span> : 'Institution Name'} </Typography>

                          <Typography sx={{ fontSize: "12px", color: "#777777" }}>{company_type && company_type !== '' ?
                            <span>{company_type.length > 18 ? company_type.substring(0, 18).concat("...") : company_type} </span> : ''} </Typography> */}

                          {company_name && company_name.length > 18 ?
                            <Tooltip title={(company_name ?? '')} componentsProps={{
                              tooltip: {
                                sx: {
                                  bgcolor: 'gray',
                                  padding: '8px',
                                  textTransform: 'capitalize',
                                },
                              },
                            }} >
                              <Typography className='transform' sx={{ fontSize: "15px", color: "black", fontWeight: "600", cursor: 'pointer' }}>{company_name && company_name !== '' ?
                                <span>{company_name.length > 18 ? company_name.substring(0, 18).concat("...") : company_name} </span> : 'Institution Name'} </Typography>
                            </Tooltip>

                            :
                            <Typography className='transform' sx={{ fontSize: "15px", color: "black", fontWeight: "600" }}>{company_name && company_name !== '' ?
                              company_name : 'Institution Name'} </Typography>
                          }

                        </Grid>
                      </Grid>
                    </Card>
                  </Box>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box style={{ backgroundColor: "#eef3fe", height: "100%", position: "relative" }}>
                      <Box py={1} style={{ height: "100%" }}>
                        <Typography className='font-weight-bold' style={{ fontSize: "1rem" }} align='center'>{companyTypeData.name} </Typography>
                        <Box mt={2} style={{ display: "flex", justifyContent: "center" }}>
                          <img src={HeadHunting} alt="head hunting" className='img-fluid' style={{ width: '48%' }} />
                        </Box>
                        {console.log('rendered 2')}
                        <Box p={1}>
                          <List>
                            {companyTypeData && companyTypeData.length > 0 ? companyTypeData.map((row) => {
                              return (
                                <ListItem style={{ padding: "6px 16px", alignItems: 'start' }}>
                                  <ListItemIcon style={{ marginRight: "0.5rem", marginTop: '2px' }}>
                                    <CheckCircleOutline style={{ color: "#56bcab", fontSize: "1rem" }} />
                                  </ListItemIcon>
                                  <ListItemText className='leftPanelList' primary={row.name} style={{ color: "#000", fontWeight: 500, fontSize: '13px' }} />
                                </ListItem>
                              )
                            })
                              : ""}
                          </List>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Drawer>
              }
            </>
          )}
        </>
      )
      }
    </RootStyle >
  );
}
