import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  errorProfile: null,
  events: [],
  statess: [],
  cities: [],
  pinCode: [],
  profileUpdate: [],
  profileServiceCreate: [],
  profileServiceUpdate: [],
  ProfileServiceGet: [],
  ProfileServiceDelete: [],
  redirectProjectYes: [],
  profileImage: [],
  dashboardDataSucc: [],
  employerlogoutRes: [],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    hasErrorProfile(state, action) {
      state.isLoading = false;
      state.errorProfile = action.payload;
    },
    // GET EVENTS
    getEventsSuccess(state, action) {

      state.isLoading = false;
      state.events = action.payload;

    },
    getStateSuccess(state, action) {
      state.isLoading = false;
      state.statess = action.payload;
    },

    getCitySuccess(state, action) {
      state.isLoading = false;
      state.cities = action.payload;
    },

    getPinSuccess(state, action) {
      state.isLoading = false;
      state.pinCode = action.payload;
    },
    ProfileUpdateSuccess(state, action) {
      state.isLoading = false;
      state.profileUpdate = action.payload;
    },
    ProfileServiceCreateSuccess(state, action) {
      state.isLoading = false;
      state.profileServiceCreate = action.payload;
    },
    ProfileServiceUpdateSuccess(state, action) {
      state.isLoading = false;
      state.profileServiceUpdate = action.payload;
    },
    ProfileServiceGetSuccess(state, action) {
      state.isLoading = false;
      state.ProfileServiceGet = action.payload;
    },
    DeleteProfileSucc(state, action) {
      state.isLoading = false;
      state.ProfileServiceDelete = action.payload;
    },

    RedirectProjectSucc(state, action) {
      state.isLoading = false;
      state.redirectProjectYes = action.payload;
    },

    getDashboardDataSucc(state, action) {
      state.isLoading = false;
      state.dashboardDataSucc = action.payload;
    },
    employerlogoutSucc(state, action) {
      state.isLoading = false;
      state.employerlogoutRes = action.payload;
    },

    // CREATE EVENT
    createEventSuccess(state, action) {
      const newEvent = action.payload;
      state.isLoading = false;
      state.events = [...state.events, newEvent];
    },

    // UPDATE EVENT
    updateEventSuccess(state, action) {
      const event = action.payload;
      const updateEvent = state.events.map((_event) => {
        if (_event.id === event.id) {
          return event;
        }
        return _event;
      });

      state.isLoading = false;
      state.events = updateEvent;
    },

    // DELETE EVENT
    deleteEventSuccess(state, action) {
      const { eventId } = action.payload;
      const deleteEvent = state.events.filter((event) => event.id !== eventId);
      state.events = deleteEvent;
    },

    // SELECT EVENT
    selectEvent(state, action) {
      const eventId = action.payload;
      state.isOpenModal = true;
      state.selectedEventId = eventId;
    },

    // SELECT RANGE
    selectRange(state, action) {
      const { start, end } = action.payload;
      state.isOpenModal = true;
      state.selectedRange = { start, end };
    },

    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },
    setProfileImage(state, action) {
      state.isLoading = false;
      state.profileImage = action.payload;
    },

  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function getProfileData() {
  const tokenVal = window.localStorage.getItem("accessToken")
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get_institution_details/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization:
            `Bearer ${tokenVal}`,
        },
      });
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getEventsSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))

      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
        dispatch(slice.actions.hasErrorProfile(error))
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}


export function getPinNo(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_pincode_location/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getPinSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function updateProfile(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/update_institution_details/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.ProfileUpdateSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function createProfileServices(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/institute_services/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.ProfileServiceCreateSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }

    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function updateProfileServices(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/institute_services/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.ProfileServiceUpdateSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }

    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getProfileServices(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.get('/get_institute_services/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.ProfileServiceGetSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function DeleteProfileServiceData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/delete_institute_services/${data}`)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.DeleteProfileSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }

    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getDashboardData() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/get_institution_dashboard/`)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getDashboardDataSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }

    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function employerLogout(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/logout/', data)
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.employerlogoutSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}



export function RedirectProject() {

  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = 'Yes';
      dispatch(slice.actions.RedirectProjectSucc(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearSkilSetDataProfilr(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.ProfileUpdateSuccess([]));
      dispatch(slice.actions.ProfileServiceCreateSuccess([]));
      dispatch(slice.actions.ProfileServiceUpdateSuccess([]));
      dispatch(slice.actions.DeleteProfileSucc([]));
      dispatch(slice.actions.RedirectProjectSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setAvatarImage(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/update_profile_pic/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.setProfileImage(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function clearHasError(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.hasError([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function cleardata(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getEventsSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function clearPindata(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getPinSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function clearDashboarddata(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getDashboardDataSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function clearEmployerdata(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.employerlogoutSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function clearProfileImagedata() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setProfileImage([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}