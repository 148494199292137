import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';



const initialState = {
    isLoading: false,
    error: null,
    TrainerAlbumData: [],
    CreateTrainerAlbumData: [],
    DeleteTrainerAlbumData: [],
    UpdateTrainerAlbumData: [],
    uploadAlbumImagesData: [],
    getAlbumImagesData: [],
    updateCoverPhotoData: [],
    updateImageData: [],
    DeleteAlbumImagesData: [],
    isOpenModal: false,
    selectedEventId: null,
    selectedRange: null,
};

const slice = createSlice({
    name: 'gallery',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        // GET EVENTS
        getTrainerAlbumSucc(state, action) {
            state.isLoading = false;
            state.TrainerAlbumData = action.payload;
        },
        createTrainerAlbumSucc(state, action) {
            state.isLoading = false;
            state.CreateTrainerAlbumData = action.payload;
        },
        deleteTrainerAlbumSucc(state, action) {
            state.isLoading = false;
            state.DeleteTrainerAlbumData = action.payload;
        },
        updateTrainerAlbumSucc(state, action) {
            state.isLoading = false;
            state.UpdateTrainerAlbumData = action.payload;
        },
        uploadAlbumImagesSucc(state, action) {
            state.isLoading = false;
            state.uploadAlbumImagesData = action.payload;
        },
        getAlbumImagesSucc(state, action) {
            state.isLoading = false;
            state.getAlbumImagesData = action.payload;
        },
        updateCoverPhotoSucc(state, action) {
            state.isLoading = false;
            state.updateCoverPhotoData = action.payload;
        },
        updateImageDataSucc(state, action) {
            state.isLoading = false;
            state.updateImageData = action.payload;
        },
        DeleteAlbumImagesSucc(state, action) {
            state.isLoading = false;
            state.DeleteAlbumImagesData = action.payload;
        },

    }

})

// Reducer
export default slice.reducer;


// Action

// ----------------------------------------------------------------------



export function getTrainerAlbum(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/get_trainer_albums/', data);
            if (response && response !== '') {
                dispatch(slice.actions.getTrainerAlbumSucc(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data))
            }
        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}


export function createTrainerAlbum(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/create_trainer_albums/', data);
            if (response && response !== '') {
                dispatch(slice.actions.createTrainerAlbumSucc(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data))
            }
        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}
export function deleteTrainerAlbum(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/delete_trainer_album/', data);
            if (response && response !== '') {
                dispatch(slice.actions.deleteTrainerAlbumSucc(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data))
            }
        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}
export function updateTrainerAlbum(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/update_trainer_albums/', data);
            if (response && response !== '') {
                dispatch(slice.actions.updateTrainerAlbumSucc(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data))
            }
        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}
export function updateCoverPhoto(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/update_cover_photo/', data);
            if (response && response !== '') {
                dispatch(slice.actions.updateCoverPhotoSucc(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data))
            }
        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}


export function UploadAlbumImages(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/upload_album_photos/', data);
            if (response && response !== '') {
                dispatch(slice.actions.uploadAlbumImagesSucc(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data))
            }
        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}

export function UpdateImageData(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/update_album_photo/', data);
            if (response && response !== '') {
                dispatch(slice.actions.updateImageDataSucc(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data))
            }
        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}



export function getAlbumImages(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/get_trainer_photos/', data);
            if (response && response !== '') {
                dispatch(slice.actions.getAlbumImagesSucc(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data))
            }
        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}

export function DeleteAlbumImages(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/delete_multiple_photos/', data);
            if (response && response !== '') {
                dispatch(slice.actions.DeleteAlbumImagesSucc(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data))
            }
        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}

export function ClearDeleteAlbumImages(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.DeleteAlbumImagesSucc([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function ClearUploadAlbumImages(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.uploadAlbumImagesSucc([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function ClearUpdateImageData(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.updateImageDataSucc([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function ClearUpdateCoverPhoto(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.updateCoverPhotoSucc([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function ClearCreateTrainerAlbum(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.createTrainerAlbumSucc([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function ClearDeleteTrainerAlbum(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.deleteTrainerAlbumSucc([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function ClearUpdateTrainerAlbum(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.updateTrainerAlbumSucc([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function clearHasError(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.hasError([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}