import React, { useState, useEffect } from 'react';

import {
  Dialog,
  Box,
 IconButton
} from '@mui/material'; 
import { alpha } from '@mui/material/styles';
import Iconify from '../../../components/Iconify';
import Image from '../../../components/Image'; 

const PreviewModal = (props) => {
    const { onClose, selectedValue, open, currentUser} = props;
    const handleClose = () => {
        onClose();
      };
  const imageData = currentUser; 
  const [mediaPath,setMediaPath] = useState(); 
  useEffect(() => {
        
      if(imageData)
      {
        setMediaPath(imageData)
      }
  },[imageData])
     
  return (
    <Dialog maxWidth="xs" onClose={handleClose} sx={{}} open={open} fullWidth>
    <Box
      sx={{
        margin: 4,
        width: 293,
      }}
    >
    <IconButton onClick={handleClose} edge="end" size="small" sx={{
                top: 10,
                p: '2px',
                right: 15, 
                position: 'absolute',
                color: 'common.white',
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                '&:hover': {
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                },
            }}>
    <Iconify icon={'eva:close-fill'} />
    </IconButton>
     {/* <Card sx={{ display: 'flex', alignItems: 'center', p: 3, cursor: 'pointer' }}> */}
     <Image src={mediaPath} alt="about-vision" />
      {/* </Card> */}
      
      </Box>
  </Dialog>
  )
}

export default PreviewModal