import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  events: [],
  calenderData: "",
  crEventAppointment: [],
  upEventAppointment: [],
  crEventWork: [],
  upEventWork: [],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getDateSuccess(state, action) {
      state.isLoading = false;
      state.calenderData = action.payload;
    },

    getEventsSuccess(state, action) {
      state.isLoading = false;
      state.events = action.payload;
    },

    // CREATE EVENT
    createEventSuccess(state, action) {
      const newEvent = action.payload;
      state.isLoading = false;
      state.events = [...state.events, newEvent];
    },

    // UPDATE EVENT
    updateEventSuccess(state, action) {
      const event = action.payload;
      const updateEvent = state.events.map((_event) => {
        if (_event.id === event.id) {
          return event;
        }
        return _event;
      });

      state.isLoading = false;
      state.events = updateEvent;
    },

    updateEventAppointment(state, action) {
      const event = action.payload;
      state.isLoading = false;
      state.upEventAppointment = action.payload;
    },

    createEventAppointment(state, action) {
      const event = action.payload;
      state.isLoading = false;
      state.crEventAppointment = action.payload;
    },

    updateEventWork(state, action) {
      const event = action.payload;
      state.isLoading = false;
      state.upEventWork = action.payload;
    },

    createEventWork(state, action) {
      const event = action.payload;
      state.isLoading = false;
      state.crEventWork = action.payload;
    },


    // DELETE EVENT
    deleteEventSuccess(state, action) {
      const { eventId } = action.payload;
      const deleteEvent = state.events.filter((event) => event.id !== eventId);
      state.events = deleteEvent;
    },

    deleteEventProjectSuccess(state, action) {
      state.isLoading = false;
      state.deleteprojectdata = action.payload;
    },

    // SELECT EVENT
    selectEvent(state, action) {
      const eventId = action.payload;
      // state.isOpenModal = true;
      state.selectedEventId = eventId;
    },

    // SELECT RANGE
    selectRange(state, action) {
      const { start, end } = action.payload;
      state.isOpenModal = true;
      state.selectedRange = { start, end };
    },

    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function getEvents(newEvent) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get_all_event/', newEvent);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getEventsSuccess(response && response.data && response.data.response));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }

    }
  };
}

// ----------------------------------------------------------------------

export function createWorkEngagement(updateEvent) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const validFromMillis = parseInt(Date.parse(updateEvent.start_date), 10);
    const validfrom = new Date(validFromMillis);
    const testDate = validfrom.toISOString();
    const dateStart = validfrom.toISOString().substring(0, 10);
    const validFromMill = parseInt(Date.parse(updateEvent.end_date), 10);
    const validfrom1 = new Date(validFromMill);
    const dateEnd = validfrom1.toISOString().substring(0, 10);
    const obj = {
      event_type: updateEvent.event_type,
      title: updateEvent.title,
      description: updateEvent.description,
      appointment_date: dateStart,
      start_date: dateStart,
      end_date: dateEnd,
    }

    try {
      const response = await axios.post('/create_work_engagement/', obj);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.createEventWork(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }

    }
  };
}

export function createAppointment(newEvent) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/create_appointment/', newEvent);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.createEventAppointment(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }

    }
  };
}

// ----------------------------------------------------------------------

export function updateWorkEngagement(eventId, updateEvent) {
  return async () => {
    dispatch(slice.actions.startLoading());

    const validFromMillis = parseInt(Date.parse(updateEvent.start_date), 10) + 86400000;
    const validfrom = new Date(validFromMillis);
    const testDate = validfrom.toISOString();
    const dateStart = validfrom.toISOString().substring(0, 10);

    const validFromMill = parseInt(Date.parse(updateEvent.end_date), 10);
    const validfrom1 = new Date(validFromMill);
    const dateEnd = validfrom1.toISOString().substring(0, 10);


    const obj = {
      id: eventId,
      event_type: updateEvent.event_type,
      title: updateEvent.title,
      description: updateEvent.description,
      appointment_date: dateStart,
      start_date: dateStart,
      end_date: dateEnd,
    }
    try {
      const response = await axios.post('/update_work_engagement/', obj);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.updateEventWork(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }

    }
  };
}

export function updateAppointment(eventId, updateEvent) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const obj = {
      id: eventId,
      salutation: updateEvent.salutation,
      event_type: updateEvent.event_type,
      title: updateEvent.title,
      description: updateEvent.description,
      appointment_date: updateEvent.appointment_date,
      start: updateEvent.start,
      end: updateEvent.end,
    }
    try {
      const response = await axios.post('/update_appointment/', obj);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.updateEventAppointment(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function updateEvent(eventId, updateEvent) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/update_work_engagement/', {
        eventId,
        updateEvent,
      });
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.updateEventSuccess(response.data.event));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function deleteEventProject(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // const response = await axios.post('/delete_project_event/', data);
      const response = await axios.post('/delete_event/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.deleteEventProjectSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      if (error && error.status !== '' && error.status !== undefined) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: '502', message: 'Something went wrong' }));
      }
    }
  };
}

// ----------------------------------------------------------------------
export function getDataEvent(eventId) {
  const Id = eventId
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/get_event/${eventId}`);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getDateSuccess(response));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

// ----------------------------------------------------------------------

export function deleteEvent(eventId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/calendar/events/delete/', { eventId });
      dispatch(slice.actions.deleteEventSuccess({ eventId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function selectRange(start, end) {
  return async () => {
    dispatch(
      slice.actions.selectRange({
        start: start.getTime(),
        end: end.getTime(),
      })
    );
  };
}

// ==================================================
export function ClearLanguageIndustry(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.updateEventAppointment([]));
      dispatch(slice.actions.updateEventWork([]));
      dispatch(slice.actions.createEventWork([]));
      dispatch(slice.actions.createEventAppointment([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearCalenderEvent(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getEventsSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearHasError(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.hasError([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearDeleteProjectDatas() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.deleteEventProjectSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

