import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';



const initialState = {
    isLoading: false,
    error: null,
    courseData: [],
    PostCourseData: [],
    DeleteCourseData: [],
    UpdateCourseData: [],
    isOpenModal: false,
    selectedEventId: null,
    selectedRange: null,
};

const CourseSlice = createSlice({
    name: 'Course',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        // GET EVENTS
        getCourseSucc(state, action) {
            state.isLoading = false;
            state.courseData = action.payload;
        },
        postCourseSucc(state, action) {
            state.isLoading = false;
            state.PostCourseData = action.payload;
        },
        deleteCourseSuccess(state, action) {
            state.isLoading = false;
            state.DeleteCourseData = action.payload;
        },
        updateCourseSuccess(state, action) {
            state.isLoading = false;
            state.UpdateCourseData = action.payload;
        },

    }

})

// Reducer
export default CourseSlice.reducer;


// Actions
export const { openModal, closeModal, selectEvent } = CourseSlice.actions;

// ----------------------------------------------------------------------

export function getcourseData(data) {
    return async () => {
        dispatch(CourseSlice.actions.startLoading());
        //   const tokenVal = window.localStorage.getItem("accessToken")
        try {
            const response = await axios.post('/get_institution_course/', data);
            dispatch(CourseSlice.actions.getCourseSucc(response.data));
        } catch (error) {
            dispatch(CourseSlice.actions.hasError(error));
        }
    };
}

export function SaveCourseData(data) {
    console.log(data, '220505005');
    return async () => {
        dispatch(CourseSlice.actions.startLoading());
        //   const tokenVal = window.localStorage.getItem("accessToken")
        try {
            const response = await axios.post('/save_institution_course/', data);
            console.log(response.data, '2205050056');

            if (response && response !== '') {
                dispatch(CourseSlice.actions.postCourseSucc(response.data));
            } else {
                dispatch(CourseSlice.actions.hasError(response.data))
            }
        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(CourseSlice.actions.hasError(error));
            } else {
                dispatch(CourseSlice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}

export function ClearaddCourse(data) {
    return async () => {
        dispatch(CourseSlice.actions.startLoading());
        try {
            dispatch(CourseSlice.actions.postCourseSucc([]));
        } catch (error) {
            dispatch(CourseSlice.actions.hasError(error));
        }
    };
}

export function DeleteCourses(data) {
    return async () => {
        dispatch(CourseSlice.actions.startLoading());
        try {
            const response = await axios.post('/delete_institution_course/', data);
            if (response && response.data && response.data.status === 200) {
                dispatch(CourseSlice.actions.deleteCourseSuccess(response.data));
            } else {
                dispatch(CourseSlice.actions.hasError(response.data))
            }
        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(CourseSlice.actions.hasError(error));
            } else {
                dispatch(CourseSlice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}

export function CleardeleteData(data) {
    return async () => {
        dispatch(CourseSlice.actions.startLoading());
        try {
            dispatch(CourseSlice.actions.deleteCourseSuccess([]));
        } catch (error) {
            dispatch(CourseSlice.actions.hasError(error));
        }
    };
}

export function UpdateCourseData(data) {
    console.log(data, '220505005');
    return async () => {
        dispatch(CourseSlice.actions.startLoading());
        //   const tokenVal = window.localStorage.getItem("accessToken")
        try {
            const response = await axios.post('/save_institution_course/', data);
            console.log(response.data, '2205050056');

            if (response && response !== '') {
                dispatch(CourseSlice.actions.updateCourseSuccess(response.data));
            } else {
                dispatch(CourseSlice.actions.hasError(response.data))
            }
        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(CourseSlice.actions.hasError(error));
            } else {
                dispatch(CourseSlice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}

export function ClearUpdateCourse(data) {
    return async () => {
        dispatch(CourseSlice.actions.startLoading());
        try {
            dispatch(CourseSlice.actions.updateCourseSuccess([]));
        } catch (error) {
            dispatch(CourseSlice.actions.hasError(error));
        }
    };
}

export function clearHasError(data) {
    return async () => {
        dispatch(CourseSlice.actions.startLoading());
        try {
            dispatch(CourseSlice.actions.hasError([]));
        } catch (error) {
            dispatch(CourseSlice.actions.hasError(error));
        }
    };
}