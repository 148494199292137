

import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
//
import BlockContent from './BlockContent';
import RejectionWebProject from './RejectionWebProject';
import MultiFilePreview from './MultiFilePreview';
import BlockContentProj from './BlockContentProj';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  padding: theme.spacing(5, 1),
  borderRadius: '20px',
  backgroundColor: "#ffffff",
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
}));

// ----------------------------------------------------------------------

UploadMultiFileProj.propTypes = {
  error: PropTypes.bool,
  showPreview: PropTypes.bool,
  files: PropTypes.array,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  helperText: PropTypes.node,
  sx: PropTypes.object,
};

export default function UploadMultiFileProj({
  error,
  showPreview = false,
  files,
  onRemove,
  onRemoveAll,
  onOpenModel,
  helperText,
  sx,
  ...other
}) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    ...other,
  });
  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter',
          }),
        }}
      >
        <input {...getInputProps()} />

        <BlockContentProj />
      </DropZoneStyle>

      {fileRejections.length > 0 && <RejectionWebProject fileRejections={fileRejections} />}

      {/* <MultiFilePreview onOpenModel={onOpenModel} files={files} showPreview={showPreview} onRemove={onRemove} onRemoveAll={onRemoveAll} /> */}

      {helperText && helperText}
    </Box>
  );
}
