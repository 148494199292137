import useAuth from '../hooks/useAuth';
// utils

export default function DateFormat({ date }) {

  const { user } = useAuth(); 
  return (
    // <p  style={{color:'#4a5c7e',fontWeight:'700',marginLeft: '10px'}}>
        <span>
            {date
        ? 
          new Intl.DateTimeFormat('en-GB', { 
            month: 'short', 
            day: '2-digit',
            year: 'numeric', 
        }).format(new Date(date))
    : 'NA'}

           
    
    </span>
    // </p>
  );
}
// ==== 1 ====
// {new Intl.DateTimeFormat('en-GB', { 
//   month: 'long', 
//   day: '2-digit',
//   year: 'numeric', 
// }).format(new Date(date))}

// ==== 2 ====
// new Date(date).toLocaleDateString('en-us', {
//   year: 'numeric',
//   month: 'short',
//   day: 'numeric',
// })