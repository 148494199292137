import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  talentData: [],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
  getSearchSuccessRes: [],
  getProfessionsRes: [],
  getadvancedProfessionsRes: [],
  catData: [],
  createAdvanceSearchRes: [],
  modalGetData: []
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getCatSucc(state, action) {
      state.isLoading = false;
      state.catData = action.payload;
    },
        // GET EVENTS
    getSuccess(state, action) {
      state.isLoading = false;
      state.getallKeywordRes = action.payload;
    },
    // GET EVENTS
    getProfessionsSuccess(state, action) {
      state.isLoading = false;
      state.getProfessionsRes = action.payload;
    },
    // GET EVENTS
    getAdvancedProfessionsSuccess(state, action) {
      state.isLoading = false;
      state.getadvancedProfessionsRes = action.payload;
    },
    createAdvanceSearchSuccs(state, action) {
      state.isLoading = false;
      state.createAdvanceSearchRes = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function getAdvancedSearchData() {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.get('/get_all_keywords/');
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getSuccess(response.data))
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function getProfessions(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/get_professions/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getProfessionsSuccess(response.data))
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function ClearAdvancedProfessions() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getProfessionsSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAdvancedProfessions(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/get_professions/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getAdvancedProfessionsSuccess(response.data))
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function ClearProfessionsAdvanced() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getAdvancedProfessionsSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearAdvancedSearch() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getCatData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.get('/get_talent_type/');
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.getCatSucc(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  };
}

export function createAdvanceSearch(data) {
  return async () => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post('/advanced_search/', data);
      if (response && response.data && response.data.status === 200) {
        dispatch(slice.actions.createAdvanceSearchSuccs(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data))
      }
    } catch (error) {
      if ((error && error.status !== "") && (error.status !== undefined)) {
        dispatch(slice.actions.hasError(error));
      } else {
        dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
      }
    }
  }
}

export function clearAdvanceSearchResponse() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.createAdvanceSearchSuccs([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function cleargetSearch() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getSearchSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearHasError(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.hasError([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}