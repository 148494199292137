import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';



const initialState = {
    isLoading: false,
    error: null,
    FacultyData: [],
    PostFacultyData: [],
    PostAdmissionsData: [],
    UpdateAdmissionsData: [],
    DeleteFacultyData: [],
    DeleteAdmissionData: [],
    UpdateEducationFacultyData: [],
    UpdatedFacultyData: [],
    AdmissionsData: [],
    // PostAdmissionsData:[],
    isOpenModal: false,
    selectedEventId: null,
    selectedRange: null,
};

const Slice = createSlice({
    name: 'Course',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        // GET EVENTS
        getFacultySucc(state, action) {
            state.isLoading = false;
            state.FacultyData = action.payload;
        },
        getAdmissionsSucc(state, action) {
            state.isLoading = false;
            state.AdmissionsData = action.payload;
        },
        postAdmissionsSucc(state, action) {
            state.isLoading = false;
            state.PostAdmissionsData = action.payload;
        },
        postFacultySucc(state, action) {
            state.isLoading = false;
            state.PostFacultyData = action.payload;
        },
        // postAdmissionsSucc(state, action) {
        //     state.isLoading = false;
        //     state.PostAdmissionsData = action.payload;
        // },

        deleteFacultySuccess(state, action) {
            state.isLoading = false;
            state.DeleteFacultyData = action.payload;
        },
        deleteAdmSuccess(state, action) {
            state.isLoading = false;
            state.DeleteAdmissionData = action.payload;
        },
        updateFacultySuccess(state, action) {
            state.isLoading = false;
            state.UpdatedFacultyData = action.payload;
        },
        updateAdmissionsSuccess(state, action) {
            state.isLoading = false;
            state.UpdateAdmissionsData = action.payload;
        },

    }

})

// Reducer
export default Slice.reducer;


// Actions
export const { openModal, closeModal, selectEvent } = Slice.actions;

// ----------------------------------------------------------------------

export function getFacultyData(data) {
    return async () => {
        dispatch(Slice.actions.startLoading());
        //   const tokenVal = window.localStorage.getItem("accessToken")
        try {
            const response = await axios.post('/get_institution_faculty/', data);
            dispatch(Slice.actions.getFacultySucc(response.data));
        } catch (error) {
            dispatch(Slice.actions.hasError(error));
        }
    };
}



// admission//

export function getAdmissionsData(data) {
    return async () => {
        dispatch(Slice.actions.startLoading());
        //   const tokenVal = window.localStorage.getItem("accessToken")
        try {
            const response = await axios.post('/get_institution_admissions/', data);
            dispatch(Slice.actions.getAdmissionsSucc(response.data));
        } catch (error) {
            dispatch(Slice.actions.hasError(error));
        }
    };
}


export function SaveAdmissionsData(data) {
    console.log(data, '220505005');
    return async () => {
        dispatch(Slice.actions.startLoading());
        //   const tokenVal = window.localStorage.getItem("accessToken")
        try {
            const response = await axios.post('/save_institution_admissions/', data);
            console.log(response.data, '2205050056');

            if (response && response !== '') {
                dispatch(Slice.actions.postAdmissionsSucc(response.data));
            } else {
                dispatch(Slice.actions.hasError(response.data))
            }
        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(Slice.actions.hasError(error));
            } else {
                dispatch(Slice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}


export function DeleteAdmission(data) {
    return async () => {
        dispatch(Slice.actions.startLoading());
        try {
            const response = await axios.post('/delete_institution_admissions/', data);
            if (response && response.data && response.data.status === 200) {
                dispatch(Slice.actions.deleteAdmSuccess(response.data));
            } else {
                dispatch(Slice.actions.hasError(response.data))
            }
        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(Slice.actions.hasError(error));
            } else {
                dispatch(Slice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}

export function CleardeleteAdmData(data) {
    return async () => {
        dispatch(Slice.actions.startLoading());
        try {
            dispatch(Slice.actions.deleteAdmSuccess([]));
        } catch (error) {
            dispatch(Slice.actions.hasError(error));
        }
    };
}

export function UpdateAdmissionsData(data) {
    console.log(data, '220505005');
    return async () => {
        dispatch(Slice.actions.startLoading());
        //   const tokenVal = window.localStorage.getItem("accessToken")
        try {
            const response = await axios.post('/save_institution_admissions/', data);
            console.log(response.data, '2205050056');

            if (response && response !== '') {
                dispatch(Slice.actions.updateAdmissionsSuccess(response.data));
            } else {
                dispatch(Slice.actions.hasError(response.data))
            }
        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(Slice.actions.hasError(error));
            } else {
                dispatch(Slice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}

export function ClearUpdateAdmissions(data) {
    return async () => {
        dispatch(Slice.actions.startLoading());
        try {
            dispatch(Slice.actions.updateAdmissionsSuccess([]));
        } catch (error) {
            dispatch(Slice.actions.hasError(error));
        }
    };
}


export function SaveFacultyData(data) {
    console.log(data, '220505005');
    return async () => {
        dispatch(Slice.actions.startLoading());
        //   const tokenVal = window.localStorage.getItem("accessToken")
        try {
            const response = await axios.post('/save_institution_faculty/', data);
            console.log(response.data, '2205050056');

            if (response && response !== '') {
                dispatch(Slice.actions.postFacultySucc(response.data));
            } else {
                dispatch(Slice.actions.hasError(response.data))
            }
        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(Slice.actions.hasError(error));
            } else {
                dispatch(Slice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}

export function ClearaddFaculty(data) {
    return async () => {
        dispatch(Slice.actions.startLoading());
        try {
            dispatch(Slice.actions.postFacultySucc([]));
        } catch (error) {
            dispatch(Slice.actions.hasError(error));
        }
    };
}


export function ClearaddAdmissions(data) {
    return async () => {
        dispatch(Slice.actions.startLoading());
        try {
            dispatch(Slice.actions.postAdmissionsSucc([]));
        } catch (error) {
            dispatch(Slice.actions.hasError(error));
        }
    };
}



export function UpdateFacultyData(data) {
    console.log(data, '220505005');
    return async () => {
        dispatch(Slice.actions.startLoading());
        //   const tokenVal = window.localStorage.getItem("accessToken")
        try {
            const response = await axios.post('/save_institution_faculty/', data);
            console.log(response.data, '2205050056');

            if (response && response !== '') {
                dispatch(Slice.actions.updateFacultySuccess(response.data));
            } else {
                dispatch(Slice.actions.hasError(response.data))
            }
        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(Slice.actions.hasError(error));
            } else {
                dispatch(Slice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}

export function ClearUpdateFaculty(data) {
    return async () => {
        dispatch(Slice.actions.startLoading());
        try {
            dispatch(Slice.actions.updateFacultySuccess([]));
        } catch (error) {
            dispatch(Slice.actions.hasError(error));
        }
    };
}

export function DeleteFaculty(data) {
    return async () => {
        dispatch(Slice.actions.startLoading());
        try {
            const response = await axios.post('/delete_institution_faculty/', data);
            if (response && response.data && response.data.status === 200) {
                dispatch(Slice.actions.deleteFacultySuccess(response.data));
            } else {
                dispatch(Slice.actions.hasError(response.data))
            }
        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(Slice.actions.hasError(error));
            } else {
                dispatch(Slice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}

export function CleardeleteData(data) {
    return async () => {
        dispatch(Slice.actions.startLoading());
        try {
            dispatch(Slice.actions.deleteFacultySuccess([]));
        } catch (error) {
            dispatch(Slice.actions.hasError(error));
        }
    };
}

export function clearHasError(data) {
    return async () => {
        dispatch(Slice.actions.startLoading());
        try {
            dispatch(Slice.actions.hasError([]));
        } catch (error) {
            dispatch(Slice.actions.hasError(error));
        }
    };
}
