import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { FormHelperText } from '@mui/material';
import { DesktopDatePicker } from '@mui/lab';
// ----------------------------------------------------------------------

RHFDatepickerYear.propTypes = {
  name: PropTypes.string,
};

export default function RHFDatepickerYear({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div id={error?.message && "errorDate"}>

          <DesktopDatePicker {...field} fullWidth error={!!error} helperText={error?.message} {...other}>
            .
          </DesktopDatePicker>
          {!!error && (
            <FormHelperText error sx={{ px: 2 }}>
              {/* {error.message} */}
              {/* Date is required */}
              {error.type === 'typeError' && <>Year is required</>}
              {(error.type === 'max' || error.type === 'min') && error.message}
            </FormHelperText>
          )}{' '}
        </div>
      )}
    />
  );
}
