import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';


const initialState = {
    isLoading: false,
    error: null,
    studentData: [],
    PostStudentData: [],
    DeleteStudentData: [],
    UpdatedStudentData: [],
    StudentExcelData: [],
    UploadBulkDataResp: [],
    PlacementInchargeData: [],
    PlacementGetInchargeData: [],
    getStudentDetailsResp: [],
    DeleteStudentDetailsData: [],
    isOpenModal: false,
    selectedEventId: null,
    selectedRange: null,
};



const slice = createSlice({
    name: 'StudentDB',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        // GET EVENTS
        getStudentSucc(state, action) {
            state.isLoading = false;
            state.studentData = action.payload;
        },
        postStudentSucc(state, action) {
            state.isLoading = false;
            state.PostStudentData = action.payload;
        },
        deleteStudentSuccess(state, action) {
            state.isLoading = false;
            state.DeleteStudentData = action.payload;
        },
        updateStudentSuccess(state, action) {
            state.isLoading = false;
            state.UpdatedStudentData = action.payload;
        },
        uploadStudentExcelSuccess(state, action) {
            state.isLoading = false;
            state.StudentExcelData = action.payload;
        },
        postInChargeSucc(state, action) {
            state.isLoading = false;
            state.PlacementInchargeData = action.payload;
        },
        getInChargeSucc(state, action) {
            state.isLoading = false;
            state.PlacementGetInchargeData = action.payload;
        },
        // UploadBulkSuccess(state, action) {
        //     state.isLoading = false;
        //     state.UploadBulkDataResp = action.payload;
        // },
        CollectStudentDetailsResp(state, action) {
            state.isLoading = false;
            state.getStudentDetailsResp = action.payload;
        },
        deleteStudentDetailsSuccess(state, action) {
            state.isLoading = false;
            state.DeleteStudentDetailsData = action.payload;
        },
    }
})

export default slice.reducer;


export const { openModal, closeModal, selectEvent } = slice.actions;


export function getStudentData(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        //   const tokenVal = window.localStorage.getItem("accessToken")
        try {
            const response = await axios.post('/get_student_database/', data);
            dispatch(slice.actions.getStudentSucc(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function SaveStudentData(data) {
    console.log(data, '220505005');
    return async () => {
        dispatch(slice.actions.startLoading());
        //   const tokenVal = window.localStorage.getItem("accessToken")
        try {
            const response = await axios.post('/save_student_database/', data);
            console.log(response.data, '2205050056');

            if (response && response !== '') {
                dispatch(slice.actions.postStudentSucc(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data))
            }
        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}

export function ClearaddStudent(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.postStudentSucc([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function DeleteStudent(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/delete_student_database/', data);
            if (response && response.data && response.data.status === 200) {
                dispatch(slice.actions.deleteStudentSuccess(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data))
            }
        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}

export function CleardeleteData(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.deleteStudentSuccess([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function UpdateStudentData(data) {
    console.log(data, '220505005');
    return async () => {
        dispatch(slice.actions.startLoading());
        //   const tokenVal = window.localStorage.getItem("accessToken")
        try {
            const response = await axios.post('/save_student_database/', data);
            console.log(response.data, '2205050056');

            if (response && response !== '') {
                dispatch(slice.actions.updateStudentSuccess(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data))
            }
        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}

export function ClearUpdateStudent(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.updateStudentSuccess([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function UploadStudentExcelData(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        //   const tokenVal = window.localStorage.getItem("accessToken")
        console.log(data, '220505005');
        try {
            const response = await axios.post('/upload_student_details/', data);
            console.log(response.data, '2205050056');

            if (response && response !== '') {
                dispatch(slice.actions.uploadStudentExcelSuccess(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data))
            }
        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}

export function ClearUploadSuccess(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.uploadStudentExcelSuccess([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}



// export function clearHasError(data) {
//     return async () => {
//         dispatch(slice.actions.startLoading());
//         try {
//             dispatch(slice.actions.uploadStudentExcelSuccess([]));
//         } catch (error) {
//             dispatch(slice.actions.hasError(error));
//         }
//     };
// }

export function clearHasError(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.hasError([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function SaveInChargeData(data) {
    console.log(data, '220505005');
    return async () => {
        dispatch(slice.actions.startLoading());
        //   const tokenVal = window.localStorage.getItem("accessToken")
        try {
            const response = await axios.post('/save_institute_placement/', data);
            console.log(response.data, '2205050056');

            if (response && response !== '') {
                dispatch(slice.actions.postInChargeSucc(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data))
            }
        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}

export function ClearInchargeData(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.postInChargeSucc([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}



export function getInChargeData() {
    return async () => {
        dispatch(slice.actions.startLoading());
        //   const tokenVal = window.localStorage.getItem("accessToken")
        try {
            const response = await axios.get('/get_institution_placement/',);
            dispatch(slice.actions.getInChargeSucc(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ---------------------studetails-------------

export function getStudentDetails(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        const tokenVal = window.localStorage.getItem("accessToken")
        try {
            const response = await axios.post('/get_student_details/', data)
            if (response && response.data && response.data.status === 200) {
                dispatch(slice.actions.CollectStudentDetailsResp(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data))
            }
        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}

export function DeleteStudentDetails(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/delete_student_details/', data);
            if (response && response.data && response.data.status === 200) {
                dispatch(slice.actions.deleteStudentDetailsSuccess(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data))
            }
        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}

export function CleardeleteDetailsData(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.deleteStudentDetailsSuccess([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}