import {  useState,createContext } from 'react';
import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Radio, RadioGroup, FormHelperText, FormControlLabel } from '@mui/material';
// ----------------------------------------------------------------------


const RHFRadioGroup = ({ name, options, getOptionLabel, ...other }) => {
  const { control } = useFormContext();
  const changeGender = (e) =>{
  }
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error }}) => (
        <div>
            <RadioGroup {...field} row {...other}>
              {options.map((option, index) => (
                <FormControlLabel
                  onChange={changeGender}
                  key={option}
                  value={option}
                  control={<Radio />}
                  label={getOptionLabel?.length ? getOptionLabel[index] : option}
                />
              ))}
            </RadioGroup>

          {!!error && (
            <FormHelperText error sx={{ px: 2 }}>
              {error.message}
            </FormHelperText>
          )}
        </div>
      )}
    />
  );
}
RHFRadioGroup.propTypes = {
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  getOptionLabel: PropTypes.arrayOf(PropTypes.string),
};

export default RHFRadioGroup;