import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField, FormHelperText } from '@mui/material';
import { LoadingButton, DesktopDatePicker } from '@mui/lab';
// ----------------------------------------------------------------------

RHFDatePicker.propTypes = {
  name: PropTypes.string,
};

export default function RHFDatePicker({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div>
          <DesktopDatePicker {...field} fullWidth error={!!error} helperText={error?.message} {...other}>
            .
          </DesktopDatePicker>
          {!!error && (
            <FormHelperText error sx={{ px: 2 }}>
              {/* {error.message} */}
              {/* Date is required */}
              {name && name === 'year' ? 'Year is required' : 'Date is required' }   
            </FormHelperText>
          )}{' '}
        </div>
      )}
    />
  );
}
