// ----------------------------------------------------------------------

export const industry = [
  'Film & OTT ',
  'Advertising (Print+TV+Outdoor) ',
  'Internet & Web ',
  'Animation and Visual Effects',
  'Architecture',
  'Broadcast & TV Studio ',
  'Culinary Arts ',
  'Digital Arts ',
  'Dance',
  'Event Management ',
  'Fashion & Modeling ',
  'Music & Bands ',
  'Performing Arts ',
  'Photography ',
  'Gaming & Virtual Reality',
  'Publishing & Journalism ',
  'Radio',
  'Product Design ',
  'Television ',
  'Traditional Arts ',
  'Voice & Singing '
];


export const industryCaption = [
  'Jobs in Film & OTT Industries',
  'Jobs in Advertising Agencies',
  'Jobs in Internet & Web',
  'Animation and Visual Effects',
  'Jobs in Architecture & Interior Design',
  'Jobs in Broadcast & TV Studio',
  'Jobs in International Hotels & Catering',
  'Jobs in Digital Media',
  'Jobs in Dance & Choreography Industries',
  'Jobs in Events & PR Management',
  'Jobs in Modelling Industries',
  'Jobs in music Industry & Rock Bands',
  'Jobs in Theatre Stage & Standup',
  'Jobs for Photographers & Studios',
  'Jobs in Gaming & AR/VR Industries',
  'Jobs for Journalists & Reporters',
  'Jobs for Radio Jockey',
  'Jobs in Product Design & Prototyping',
  'Jobs in TV & Media',
  'Jobs for Painter, Sculptor & Craftsman',
  'Jobs in Singing & Voice Over Industries'

];
