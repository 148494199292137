import jwtDecode from 'jwt-decode';
import { verify, sign } from 'jsonwebtoken';
//
import axios from './axios';

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  // ----------------------------------------------------------------------

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};



// ----------------------------------------------------------------------

const setSession = (accessToken, user = "") => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;


    if (user && user.user && user.user.enc_id) {
      localStorage.setItem('userTrainerEncId', user && user.user && user.user.enc_id);
      localStorage.setItem('userEmployerEncId', user && user.user && user.user.enc_id);
    }
    if (user && user.user && user.user.photo_url) {
      localStorage.setItem('userPhotourlEmp', user && user.user && user.user.photo_url);
    }

    if (user && user.menu) {
      localStorage.setItem('userMenu', JSON.stringify(user && user.menu));
    }
    if (user.flag && user.flag === "Yes") {
      console.log(user.flag, 'user.flag463463')
      localStorage.setItem('companyProfile', 'Filled');
    }
    // if (user.completion_tabs && user.completion_tabs.length === 2) {
    //    localStorage.setItem('companyProfile','Filled');
    // }

  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userTrainerEncId');
    localStorage.removeItem('userEmployerEncId');
    localStorage.removeItem('userPhotourlEmp');
    localStorage.removeItem('userMenu');
    localStorage.removeItem('companyProfile');
    localStorage.removeItem('userPhotourlEmp');
    localStorage.removeItem('companyProfileFilled');
    localStorage.removeItem('userSlug');
    localStorage.removeItem('userIcon');
    delete axios.defaults.headers.common.Authorization;
  }
};

export { isValidToken, setSession, verify, sign };
