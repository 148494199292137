import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

const initialState = {
    isLoading: false,
    error: null,
    isOpenModal: false,
    selectedEventId: null,
    selectedRange: null,
    getCorsesDashboardResp: [],
    applicantsDetails: [],
    CourseSettingsDetails: [],
    SaveCourseSettingsDetails: [],
    UpdateCorsesDashData: [],
};

const slice = createSlice({
    name: 'course_dashboard',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET EVENTS

        getCorsesDashboardSuccess(state, action) {
            state.isLoading = false;
            state.getCorsesDashboardResp = action.payload;
        },

        getApplicantsDetails(state, action) {
            state.isLoading = false;
            state.applicantsDetails = action.payload
        },
        getCourseSettingsData(state, action) {
            state.isLoading = false;
            state.CourseSettingsDetails = action.payload
        },
        postSaveCourseSettingsData(state, action) {
            state.isLoading = false;
            state.SaveCourseSettingsDetails = action.payload
        },
        updateCorsesDashSucc(state, action) {
            state.isLoading = false;
            state.UpdateCorsesDashData = action.payload
        },

        // OPEN MODAL
        openModal(state) {
            state.isOpenModal = true;
        },

        // CLOSE MODAL
        closeModal(state) {
            state.isOpenModal = false;
            state.selectedEventId = null;
            state.selectedRange = null;
        },


    },
});


// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;



export function getCorsesDashboard(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        const tokenVal = window.localStorage.getItem("accessToken")
        try {
            const response = await axios.post('/get_course_dashboard/', data)
            if (response && response.data && response.data.status === 200) {
                dispatch(slice.actions.getCorsesDashboardSuccess(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data))
            }
        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}

export function UpdateCorsesDashboard(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/update_course_dashboard/', data)
            if (response && response.data && response.data.status === 200) {
                dispatch(slice.actions.updateCorsesDashSucc(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data))
            }
        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}

export function ClearUpdateCorsesDashboard(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.updateCorsesDashSucc([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}






export function clearHasError(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.hasError([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getApplicantsDetails(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        // const tokenVal = window.localStorage.getItem("accessToken")
        try {
            const response = await axios.post('/get_institute_applicants/', data)
            if (response && response.data && response.data.status === 200) {
                dispatch(slice.actions.getApplicantsDetails(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data))
            }
        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}

export function getCourseSettings(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        //   const tokenVal = window.localStorage.getItem("accessToken")
        try {
            const response = await axios.post('/get_course_settings/', data);
            dispatch(slice.actions.getCourseSettingsData(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function SaveCourseSettings(data) {
    console.log(data, '220505005');
    return async () => {
        dispatch(slice.actions.startLoading());
        //   const tokenVal = window.localStorage.getItem("accessToken")
        try {
            const response = await axios.post('/save_course_settings/', data);
            console.log(response.data, '2205050056');

            if (response && response !== '') {
                dispatch(slice.actions.postSaveCourseSettingsData(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data))
            }
        } catch (error) {
            if ((error && error.status !== "") && (error.status !== undefined)) {
                dispatch(slice.actions.hasError(error));
            } else {
                dispatch(slice.actions.hasError({ status: "502", message: "Something went wrong" }))
            }
        }
    };
}


export function ClearSaveCourseSettings(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.postSaveCourseSettingsData([]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

